import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { createSupplier } from "../../../services/api/SuppliersServices"
import { Select, Tooltip, Row, Col } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { ZONE } from './ListOptions'
class SuppliersModalNew extends React.Component {
    state = {
        name: '',
        sku_prefix: '',
        need_gen_mockup: false,
        max_mapping: '',
        available_tiktok: false,
        shipbytiktok: false,
        need_charge_have_sleeves: '',
        isLoading: false,
        errorName: '',
        errorSku: '',
        error: '',
        zone: '',
        mapping_priority: '',
        errorMappingPriority: '',
        warehouse_name: '',
        label_handling_fee: '',
        additional_fee: '',
        max_mapping_type: 'supplier',
        max_mapping_dtg: '',
        max_mapping_pet: '',
        max_mapping_sublimation: '',
    }

    _handleChangeData = (field, value) => {
        this.setState(() => {
            return {
                [field]: value,
                errorName: (field === 'name' && !value.trim()) ? 'Name is required.' : '',
                errorSku: (field === 'sku_prefix' && !value.trim()) ? 'Sku prefix is required.' : '',
                errorMappingPriority: field === 'mapping_priority' ? !!value && (!Number.isInteger(+value) || +value <= 0) ? 'Mapping priority must be a positive integer.' : (!!value && !value.trim()) ? 'Mapping priority is required.' : '' : '',
                error: ''
            }
        })
    }

    _handleChangeSelectData = (field, value) => {
        if(field === 'max_mapping_type'){
            this.setState(() => {
                return {
                    max_mapping_type: value,
                    max_mapping: '',
                    max_mapping_dtg: '',
                    max_mapping_pet: '',
                    max_mapping_sublimation: '',
                }
            })
        }else if(field === 'available_tiktok' && value === 'no'){
            this.setState(() => {
                return {
                    available_tiktok: false,
                    shipbytiktok: false,
                    warehouse_name: '',
                    label_handling_fee: '',
                    additional_fee: '',
                    error: ''
                }
            })
        }else if(field === 'shipbytiktok' && value === 'no'){
            this.setState(() => {
                return {
                    shipbytiktok: false,
                    label_handling_fee: '',
                    error: ''
                }
            })
        }else{
            this.setState(() => {
                return {
                    [field]: value === 'yes' ? true : false,
                    error: ''
                }
            })
        }
    }

    _handleClickNew = async () => {
        const {name, sku_prefix, need_gen_mockup, max_mapping, available_tiktok, need_charge_have_sleeves, shipbytiktok, zone, mapping_priority, warehouse_name, label_handling_fee, additional_fee, max_mapping_type, max_mapping_dtg, max_mapping_pet, max_mapping_sublimation} = this.state

        if (!name.trim() || !sku_prefix.trim() || !mapping_priority.trim()) return this.setState({
            errorName: (!name.trim()) ? 'Name is required.' : '',
            errorSku: (!sku_prefix.trim()) ? 'Sku prefix is required.' : '',
            errorMappingPriority: (!mapping_priority.trim()) ? 'Mapping priority is required.' : '',
            error: ''
        })

        this.setState({ isLoading: true, error: '' })

        try {
            const { refresh, toggle } = this.props

            const payload = {
                name,
                sku_prefix,
                need_gen_mockup,
                // max_mapping: max_mapping ? parseFloat(max_mapping) : null,
                max_mapping_type,
                need_charge_have_sleeves: need_charge_have_sleeves ? parseFloat(need_charge_have_sleeves) : null,
                available_tiktok,
                shipbytiktok : available_tiktok ? shipbytiktok : false,
                warehouse_name,
                label_handling_fee: label_handling_fee ? parseFloat(label_handling_fee) : null,
                additional_fee: additional_fee ? parseFloat(additional_fee) : null
            }

            if(max_mapping_type !== 'production_method'){
                payload.max_mapping = max_mapping !== null && max_mapping !== undefined && max_mapping !== '' ? parseFloat(max_mapping) : null
            }else{
                payload.max_mappings = {
                    DTG: max_mapping_dtg !== null && max_mapping_dtg !== undefined && max_mapping_dtg !== '' ? parseFloat(max_mapping_dtg) : null,
                    PET: max_mapping_pet !== null && max_mapping_pet !== undefined && max_mapping_pet !== '' ? parseFloat(max_mapping_pet) : null,
                    SUB: max_mapping_sublimation !== null && max_mapping_sublimation !== undefined && max_mapping_sublimation !== '' ? parseFloat(max_mapping_sublimation) : null
                }
            }

            if(zone) payload.zone = zone
            if(mapping_priority) payload.mapping_priority = +mapping_priority

            const { success, message } = await createSupplier(payload)
            if (!success) {
                return this.setState({
                    error: message,
                    isLoading: false
                })
            }

            refresh()
            toggle()
        } catch (e) {
            this.setState({
                error: e.message,
                isLoading: false
            })
        }
    }

    render() {
        const {isOpen, toggle} = this.props
        const {name,sku_prefix, isLoading, error, errorName, errorSku, need_gen_mockup, max_mapping, available_tiktok, need_charge_have_sleeves, shipbytiktok, warehouse_name, label_handling_fee, additional_fee, mapping_priority, errorMappingPriority, zone,
            max_mapping_type, max_mapping_dtg, max_mapping_pet, max_mapping_sublimation } = this.state

        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className="ModalNewSupplier">
                    <ModalHeader toggle={toggle}>New Supplier</ModalHeader>
                    <ModalBody>
                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label htmlFor='Name'>Name</label>
                                    <input id='Name' className='form-control' type='text' name='name' value={name || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('name', value)} required />
                                    {errorName && <div className="text-danger mt-1">{errorName}</div>}
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label htmlFor='skuPrefix'>SKU Prefix</label>
                                    <input id='skuPrefix' className='form-control' type='text' name='sku_prefix' value={sku_prefix || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('sku_prefix', value)} required />
                                    {errorSku && <div className="text-danger mt-1">{errorSku}</div>}
                                </div>
                            </Col>
                        </Row>

                        {/* <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='mappingPriority'>Mapping Priority</label>
                                    <input id='mappingPriority' className='form-control' type='number' name='mapping_priority' value={mapping_priority || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('mapping_priority', value)} />
                                    {errorMappingPriority && <div className="text-danger mt-1">{errorMappingPriority}</div>}
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='maxMapping'>Max Mapping <Tooltip title="Max capacity per day"><QuestionCircleFilled /></Tooltip></label>
                                    <input id='maxMapping' className='form-control' type='number' name='max_mapping' value={max_mapping || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('max_mapping', value)} />
                                </div>
                            </Col>
                        </Row> */}

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='zone'>Zone</label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeData('zone', value)}
                                        options={ZONE}
                                        value={zone || undefined}
                                        id='zone'
                                        allowClear
                                        placeholder="Select zone"
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                            <div className='form-group'>
                                    <label className='d-block' htmlFor='genMuckup'>Need Gen Mockup <Tooltip title="Must have mockup when pushing orders?"><QuestionCircleFilled /></Tooltip></label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeSelectData('need_gen_mockup', value)}
                                        options={[
                                            {
                                                value: 'no',
                                                label: 'No',
                                            },
                                            {
                                                value: 'yes',
                                                label: 'Yes',
                                            }
                                        ]}
                                        value={need_gen_mockup ? 'yes' : 'no'}
                                        id="genMuckup"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='availableTiktok'>Available Tiktok <Tooltip title="Available for receiving Tiktok orders?"><QuestionCircleFilled /></Tooltip></label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeSelectData('available_tiktok', value)}
                                        options={[
                                            {
                                                value: 'no',
                                                label: 'No',
                                            },
                                            {
                                                value: 'yes',
                                                label: 'Yes',
                                            }
                                        ]}
                                        value={available_tiktok ? 'yes' : 'no'}
                                        id='availableTiktok'
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='needChargeHaveSleeves'>Need charge have sleeves <Tooltip title="US supplier's sleeves printing fee"><QuestionCircleFilled /></Tooltip></label>
                                    <div className='input-currency-box'>
                                        <span className='currency'>$</span>
                                        <input id='needChargeHaveSleeves' className='form-control' type='number' name='need_charge_have_sleeves' value={need_charge_have_sleeves || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('need_charge_have_sleeves', value)} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                {
                                    available_tiktok &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='shipByTikTok'>Ship by Tiktok</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            className='customSelect'
                                            onChange={value => this._handleChangeSelectData('shipbytiktok', value)}
                                            options={[
                                                {
                                                    value: 'no',
                                                    label: 'No',
                                                },
                                                {
                                                    value: 'yes',
                                                    label: 'Yes',
                                                }
                                            ]}
                                            value={shipbytiktok ? 'yes' : 'no'}
                                            id='shipByTikTok'
                                        />
                                    </div>
                                }
                            </Col>
                            <Col span={11}>
                                {
                                    available_tiktok &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='warehouseName'>Warehouse Name <Tooltip title="The info of warehouse"><QuestionCircleFilled /></Tooltip></label>
                                        <input id='warehouseName' className='form-control' type='text' name='warehouse_name' value={warehouse_name || ""}
                                            onChange={({target: {value}}) => this._handleChangeData('warehouse_name', value)} required/>
                                    </div>
                                }
                            </Col>
                        </Row>

                        {
                            available_tiktok &&
                            <Row className='justify-content-between'>
                                <Col span={11}>
                                    {
                                        shipbytiktok &&
                                        <div className='form-group'>
                                            <label className='d-block' htmlFor='labelHandlingFee'>Label Handling Fee <Tooltip title="The fee of handling the label, current not applying for PYUS & BFUS. Leaving this field blank, system will auto charge $0.5 with TikTok orders."><QuestionCircleFilled /></Tooltip></label>
                                            <input id='labelHandlingFee' className='form-control' type='number' name='label_handling_fee' value={label_handling_fee || ""}
                                                onChange={({target: {value}}) => this._handleChangeData('label_handling_fee', value)}/>
                                        </div>
                                    }
                                </Col>

                                <Col span={11}>
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='additionalFee'>Additional Fee <Tooltip title="The additional fee of processing each item, current only applying for PYUS, SPUS & BFUS."><QuestionCircleFilled /></Tooltip></label>
                                        <input id='additionalFee' className='form-control' type='number' name='additional_fee' value={additional_fee || ""}
                                            onChange={({target: {value}}) => this._handleChangeData('additional_fee', value)}/>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {/* {
                            available_tiktok &&
                            <Row className='justify-content-between'>
                                <Col span={11}>
                                    {
                                        shipbytiktok &&
                                        <div className='form-group'>
                                            <label className='d-block' htmlFor='labelHandlingFee'>Label Handling Fee <Tooltip title="The fee of handling the label, current not applying for PYUS & BFUS. Leaving this field blank, system will auto charge $0.5 with TikTok orders."><QuestionCircleFilled /></Tooltip></label>
                                            <input id='labelHandlingFee' className='form-control' type='number' name='label_handling_fee' value={label_handling_fee || ""}
                                                onChange={({target: {value}}) => this._handleChangeData('label_handling_fee', value)}/>
                                        </div>
                                    }

                                </Col>

                                <Col span={11}>
                                    
                                </Col>
                            </Row>
                        } */}

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='maxMappingType'>Max Mapping Type</label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeSelectData('max_mapping_type', value)}
                                        options={[
                                            {
                                                value: 'supplier',
                                                label: 'By Supplier Total',
                                            },
                                            {
                                                value: 'production_method',
                                                label: 'By Production Methods',
                                            }
                                        ]}
                                        value={max_mapping_type}
                                        id='maxMappingType'
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='mappingPriority'>Mapping Priority</label>
                                    <input id='mappingPriority' className='form-control' type='number' name='mapping_priority' value={mapping_priority || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('mapping_priority', value)} />
                                    {errorMappingPriority && <div className="text-danger mt-1">{errorMappingPriority}</div>}
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                {
                                    max_mapping_type === 'production_method' &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='maxMappingPET'>Max Mapping - PET</label>
                                        <input id='maxMappingPET' className='form-control' type='number' name='max_mapping_pet' value={max_mapping_pet || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('max_mapping_pet', value)} />
                                    </div>
                                }
                                
                            </Col>
                            <Col span={11}>
                                {
                                    max_mapping_type === 'supplier' &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='maxMapping'>Max Mapping <Tooltip title="Max capacity per day"><QuestionCircleFilled /></Tooltip></label>
                                        <input id='maxMapping' className='form-control' type='number' name='max_mapping' value={max_mapping || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('max_mapping', value)} />
                                    </div>
                                }

                                {
                                    max_mapping_type === 'production_method' &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='maxMappingDTG'>Max Mapping - DTG</label>
                                        <input id='maxMappingDTG' className='form-control' type='number' name='max_mapping_dtg' value={max_mapping_dtg || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('max_mapping_dtg', value)} />
                                    </div>
                                }
                            </Col>
                        </Row>

                        {
                            max_mapping_type === 'production_method' &&
                            <Row className='justify-content-between'>
                                <Col span={11}>
                                    
                                </Col>
                                <Col span={11}>
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='maxMappingSublimation'>Max Mapping - Sublimation</label>
                                        <input id='maxMappingSublimation' className='form-control' type='number' name='max_mapping_sublimation' value={max_mapping_sublimation || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('max_mapping_sublimation', value)} />
                                    </div>
                                </Col>
                            </Row>
                        }

                        {error && <div className="text-danger mt-3">{error}</div> }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <button className='btn btn-secondary' onClick={toggle} disabled={isLoading}>Cancel</button>
                            <button className='btn btn-primary' onClick={this._handleClickNew} disabled={isLoading}>
                                New Supplier
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

SuppliersModalNew.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    className: PropTypes.string
}

export default SuppliersModalNew
