import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import FilterDate from "../../shared/FilterDate"
import { Radio, Select, message, Spin, Button } from "antd"
import { getListSuppliersV2, getIssueTypeReport, getIssueProductTypeReport, getIssueStorePackageItemReport, getIssueStorePackageReport } from '../../../services/api/SuppliersServices'
import TypeReport from './TypeReport'
import ProductTypeReport from './ProductTypeReport'
import StoreReport from './StoreReport'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment"

const defaultTime = 'thisMonth'
const defaultSup = '5cf099aa3b7f1e3d46b7ae73'
const baseLink = '/a/package-issues'

const TYPE_REPORT = [
    {
        label: 'Issue Type Report',
        value: 'type'
    },
    {
        label: 'Product Type Report',
        value: 'product_type'
    },
    {
        label: 'Store Report',
        value: 'store'
    },
]

const TYPE_EXPORT = {
    type: 'Issue_Type_Report',
    product_type: 'Issue_Product_Type_Report',
    store: {
        package: 'Issue_Store_Package_Report',
        package_item: 'Issue_Store_Package_Item_Report',
    }
}

const PackageIssueReportPage = () => {
    const [timeFilter, setTimeFilter] = useState(defaultTime)
    const [dateRange, setDateRange] = useState()
    const [supplier, setSupplier] = useState(defaultSup)
    const [listSupplier, setListSupplier] = useState([])
    const [typeReport, setTypeReport] = useState('type')
    const [loading, setLoading] = useState(false)
    const [reportData, setStaticsProductionTime] = useState({})
    const [productTypeStatics, setProductTypeStatics] = useState({})
    const [storePackageReport, setStorePackageReport] = useState({})
    const [storePackageReportItem, setStorePackageReportItem] = useState({})
    const [storeBy, setStoreBy] = useState(false)
    const [sortBy, setSortBy] = useState('item')
    const [sortItemQuantity, setSortItemQuantity] = useState(true)
    const [sortCaseQuantity, setSortCaseQuantity] = useState(true)
    const [partnerConfirm, setPartnerConfirm] = useState("1")

    useEffect(() => {
        typeReport === "type" && fetchIssueTypeReport()
        typeReport === "product_type" && fetchIssueProductTypeReport()
    }, [timeFilter, dateRange, supplier, typeReport, partnerConfirm])

    useEffect(() => {
        getListSupplier()
    }, [])

    const convertDate = (d) => {
        const p = d.split('/')

        return [p[1], p[0], p[2]].join('/')
    }

    const getListSupplier = async () => {
        try {
            const { data, success, message: mess } = await getListSuppliersV2()
            if (success) {
                setListSupplier(data.suppliers)
            } else {
                message.error(mess)
            }
        } catch (error) { }
    }

    const fetchIssueTypeReport = async () => {
        setLoading(true)
        try {

            const payloadStatic = {
                ...(!!dateRange && {
                    from: dateRange.from ? convertDate(dateRange.from) : undefined,
                    to: dateRange.to ? convertDate(dateRange.to) : undefined,
                }),
            }

            if (!!timeFilter) payloadStatic.date_range = timeFilter

            const { success, data, message: mess } = await getIssueTypeReport(payloadStatic, supplier, partnerConfirm === "1" ? "by-type" : "by-type-all-partner-confirm")
            if (!success) return message.error(mess)
            
            if(partnerConfirm === "1") return setStaticsProductionTime(data)
            
            const { type = {} } = Object.assign({}, data)
            const newData = {
                ...data,
                approveOrHalf: type,
                reject: {}
            }

            setStaticsProductionTime(newData)

        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const fetchIssueProductTypeReport = async () => {
        setLoading(true)
        try {

            const payloadStatic = {
                ...(!!dateRange && {
                    from: dateRange.from ? convertDate(dateRange.from) : undefined,
                    to: dateRange.to ? convertDate(dateRange.to) : undefined,
                }),
            }

            if (!!timeFilter) payloadStatic.date_range = timeFilter

            const { success, data, message: mess } = await getIssueProductTypeReport(payloadStatic, supplier)
            if (!success) return message.error(mess)

            setProductTypeStatics(data)

        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const fetchIssueStorePackage = async (previous_period, period) => {
        setLoading(true)
        try {
            const payloadStatic = {
                previous_period: {
                    from: previous_period.startOf('month').format('MM/DD/YYYY'),
                    to: previous_period.endOf('month').format('MM/DD/YYYY'),
                },
                period: {
                    from: period.startOf('month').format('MM/DD/YYYY'),
                    to: period.endOf('month').format('MM/DD/YYYY'),
                },
            }

            const { success, data, message: mess } = await getIssueStorePackageReport(payloadStatic, supplier)
            if (!success) return message.error(mess)

            setStorePackageReport(data)

        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const fetchIssueStorePackageItem = async (previous_period, period) => {
        setLoading(true)
        try {

            const payloadStatic = {
                previous_period: {
                    from: previous_period.startOf('month').format('MM/DD/YYYY'),
                    to: previous_period.endOf('month').format('MM/DD/YYYY'),
                },
                period: {
                    from: period.startOf('month').format('MM/DD/YYYY'),
                    to: period.endOf('month').format('MM/DD/YYYY'),
                },
            }

            const { success, data, message: mess } = await getIssueStorePackageItemReport(payloadStatic, supplier)
            if (!success) return message.error(mess)

            setStorePackageReportItem(data)

        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const _handleTimeChange = (e) => {
        const { value } = e.target
        setTimeFilter(value)
        setDateRange(false)
        setSortCaseQuantity(true)
        setSortItemQuantity(true)
        setSortBy('item')
    }

    const handleChangeDate = (e) => {
        if (e.date_range && (e.date_range.from || e.date_range.to)) {
            setTimeFilter('')
        } else {
            setTimeFilter(defaultTime)
        }
        setDateRange(e.date_range)
        setSortItemQuantity(true)
        setSortCaseQuantity(true)
        setSortBy('item')
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        setSortItemQuantity(true)
        setSortCaseQuantity(true)
        setSortBy('item')
    }

    const rangeTime2FromTo = (timeFilter) => {
        const format = 'DD-MM-YYYY'
        switch (timeFilter) {
            case 'lastWeek':
                return {
                    from: moment().startOf("isoWeek").subtract(7, 'days').format(format),
                    to: moment().endOf("isoWeek").subtract(7, 'days').format(format),
                }
            case 'lastMonth':
                return {
                    from: moment().startOf("month").subtract(1, 'months').format(format),
                    to: moment().startOf("month").subtract(1, 'day').format(format),
                }
            case 'lastQuarter':
                return {
                    from: moment().startOf("quarter").subtract(3, 'months').format(format),
                    to: moment().endOf("quarter").subtract(3, 'months').format(format),
                }
            case 'thisWeek':
                return {
                    from: moment().startOf("isoWeek").format(format),
                    to: moment().endOf("isoWeek").format(format),
                }
            case 'thisMonth':
                return {
                    from: moment().startOf("month").format(format),
                    to: moment().endOf("month").format(format),
                }
            default:
                return {
                    from: moment().startOf("quarter").format(format),
                    to: moment().endOf("quarter").format(format),
                }
        }
    }

    const linkTo = (baseLink, text, args) => {
        if (!baseLink) {
            return text
        }

        const query = {
            ...(supplier && { supplier: supplier }),
            ...(dateRange && (dateRange.from || dateRange.to) ? {
                created_at: dateRange,
            } : {
                created_at: rangeTime2FromTo(timeFilter),
            }),
            ...args,
        }

        const queryString = Object.keys(query).map(name => {
            if (query[name] === '' || query[name] === undefined || query[name] === null) return
            if (typeof query[name] !== 'object') {
                return `${name}=${query[name]}`
            }

            return Object.keys(query[name]).map(c => {
                return `${name}.${c}=${query[name][c]}`
            }).join('&')
        }).filter(Boolean).join('&')

        return <a href={`${baseLink}?${queryString}`} target={'_blank'}>{text}</a>
    }

    const showPercentIssue = (number, total) => {
        const percent = ((+number / +total) * 100)
        return `${number} (${!!percent ? Number(percent.toFixed(2)) : 0}%)`
    }

    const handleSortCaseQuantity = () => {
        setSortBy('case')
        setSortCaseQuantity(!sortCaseQuantity)
    }

    const handleSortItemQuantity = () => {
        setSortBy('item')
        setSortItemQuantity(!sortItemQuantity)
    }

    const handleChangeTypeIssue = (value) => {
        setTypeReport(value)
        setSortItemQuantity(true)
        setSortCaseQuantity(true)
        setSortBy('item')
    }

    const handleChangePartnerConfirm = (value) => {
        setPartnerConfirm(value)
    }

    const returnPageHeihgt = !!document.getElementById("returnPage") && document.getElementById("returnPage").offsetHeight
    const FilterHeight = !!document.getElementById("filterIssue") && document.getElementById("filterIssue").offsetHeight
    const { groupByProductType = {}, totalErrorQuantity = 0 } = Object.assign({}, productTypeStatics)
    const issueProductType = useMemo(() => Object.entries(groupByProductType).map(([key, value]) => ({ type: key, quantity: value })), [groupByProductType])
    const { approveOrHalf = {}, reject = {}, totalCaseQuantity = 0, totalItemQuantity = 0 } = Object.assign({}, reportData)
    const issueTypes = useMemo(() => Object.entries(approveOrHalf).map(([key, value]) => value), [approveOrHalf])
    const { caseQuantity = 0, itemQuantity = 0 } = Object.assign({}, reject)

    return (
        <div className='PackageIssueReportPage fixedHeaderIssue'>
            <div className="container-fluid">
                <div className="BackWrapper" id="returnPage">
                    <Link className="nav-link BackButton pl-0" to="/a/package-issues">
                        <i className="fas fa-chevron-left mr-2" />
                        <span>Package Issues Management</span>
                    </Link>
                </div>
                <div className="MenuTitle d-flex justify-content-between align-items-center" id="filterIssue" style={{ top: `${returnPageHeihgt}px` }}>
                    <div className="TitlePage">
                        <h3>Issue Report</h3>
                        <div align="left" className="pt-3 Filters">
                            <Select
                                onChange={handleChangeTypeIssue}
                                value={typeReport || ''}
                                style={{ minWidth: 200 }}
                                options={TYPE_REPORT}
                            />
                        </div>
                    </div>
                    <div className="Actions" >
                        {typeReport !== "store" &&
                            <>
                                <Radio.Group value={timeFilter} onChange={_handleTimeChange}>
                                    <Radio.Button value="lastQuarter">Last quarter</Radio.Button>
                                    <Radio.Button value="lastMonth">Last month</Radio.Button>
                                    <Radio.Button value="lastWeek">Last week</Radio.Button>
                                    <Radio.Button value="thisQuarter">This quarter</Radio.Button>
                                    <Radio.Button value="thisMonth">This month</Radio.Button>
                                    <Radio.Button value="thisWeek">This week</Radio.Button>
                                </Radio.Group>
                                <FilterDate
                                    heading=""
                                    field="date_range"
                                    value={dateRange || {}}
                                    onDatesChange={handleChangeDate}
                                    locale={{ lang: { rangePlaceholder: ['Created start', 'Created end'] } }}
                                    style={{ display: 'inline-block', marginLeft: '3px' }}
                                    rangePickerStyle={{ width: 250 }}
                                />
                            </>
                        }
                        <div className="pt-3 Filters d-flex justify-content-end">
                            {typeReport === "store" &&
                                <Button
                                    className="btn btn-outline-primary btn-sm px-4 rounded mr-3"
                                    onClick={() => setStoreBy(!storeBy)}
                                > {!storeBy ? "By item" : "By package"}</Button>
                            }
                            {typeReport === "type" &&
                                <Select
                                    onChange={handleChangePartnerConfirm}
                                    value={partnerConfirm || ''}
                                    style={{ minWidth: '250px' }}
                                    className='mr-3'
                                >
                                    <Select.Option value={"1"}>Classify by Partner's confirm</Select.Option>
                                    <Select.Option value={"0"}>All Partner's confirm</Select.Option>
                                </Select>
                            }
                            <Select
                                onChange={handleChangeSupplier}
                                value={supplier || ''}
                                style={{ minWidth: '150px' }}
                                showSearch
                                filterOption={(inputValue, option) => !!option && option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            >
                                <Select.Option value={''}>All</Select.Option>
                                {
                                    listSupplier.map(supplier => {
                                        return <Select.Option key={supplier._id}
                                            value={supplier._id}>{supplier.sku_prefix || supplier.name}</Select.Option>
                                    })
                                }
                            </Select>
                            <ReactHTMLTableToExcel
                                id="Export-table-xls-button"
                                className="btn btn-outline-primary btn-sm px-4 rounded ml-3"
                                table={typeReport === "store" ? TYPE_EXPORT[typeReport][storeBy ? 'package_item' : 'package'] : TYPE_EXPORT[typeReport]}
                                filename={`${typeReport === "store" ? TYPE_EXPORT[typeReport][storeBy ? 'package_item' : 'package'] : TYPE_EXPORT[typeReport]}`}
                                sheet={typeReport === "store" ? TYPE_EXPORT[typeReport][storeBy ? 'package_item' : 'package'] : TYPE_EXPORT[typeReport]}
                                buttonText="Export"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Spin spinning={loading} tip="Getting statics...">
                {
                    typeReport === 'type' &&
                    <TypeReport
                        linkTo={linkTo}
                        sortBy={sortBy}
                        baseLink={baseLink}
                        issueTypes={issueTypes}
                        FilterHeight={FilterHeight}
                        itemQuantity={itemQuantity}
                        caseQuantity={caseQuantity}
                        sortItemQuantity={sortItemQuantity}
                        totalItemQuantity={totalItemQuantity}
                        showPercentIssue={showPercentIssue}
                        returnPageHeihgt={returnPageHeihgt}
                        sortCaseQuantity={sortCaseQuantity}
                        totalCaseQuantity={totalCaseQuantity}
                        handleSortCaseQuantity={handleSortCaseQuantity}
                        handleSortItemQuantity={handleSortItemQuantity}
                        partnerConfirm={partnerConfirm}
                    />
                }
                {
                    typeReport === 'product_type' &&
                    <ProductTypeReport
                        linkTo={linkTo}
                        sortBy={sortBy}
                        baseLink={baseLink}
                        issueProductType={issueProductType}
                        FilterHeight={FilterHeight}
                        sortItemQuantity={sortItemQuantity}
                        showPercentIssue={showPercentIssue}
                        returnPageHeihgt={returnPageHeihgt}
                        sortCaseQuantity={sortCaseQuantity}
                        handleSortItemQuantity={handleSortItemQuantity}
                        totalErrorQuantity={totalErrorQuantity}
                    />
                }
                {
                    typeReport === "store" &&
                    <StoreReport
                        fetchIssueStorePackage={fetchIssueStorePackage}
                        fetchIssueStorePackageItem={fetchIssueStorePackageItem}
                        storePackageReport={storePackageReport}
                        storePackageReportItem={storePackageReportItem}
                        supplier={supplier}
                        typeReport={typeReport}
                        storeBy={storeBy}
                    />
                }
            </Spin>
        </div>
    )
}

export default PackageIssueReportPage