import React from 'react'
import ProductTitle from "./ProductsTableData/ProductTitle"
import ProductDescription from "./ProductsTableData/ProductDescription"
// import humanizeTime from "../../../../helpers/time/humanizeTime"
import PropTypes from 'prop-types'
import ProductType from "./ProductsTableData/ProductType"
import { Link } from "react-router-dom"
import ProductStoreMode from "./ProductsTableData/ProductStoreMode"
import ProductsSupplier from "./ProductsTableData/ProductsSupplier"
import { productLabelOptions } from '../../../../statics/storeModes'
import moment from "moment"


class ProductsTableRow extends React.Component {

    state = {
        id: '',
        title: '',
        description: '',
        sku_prefix: '',
        type: '',
        supplier: '',
        store_modes: ['normal']
    }

    componentDidMount() {
        this._fetchDetailProduct()
    }

    _fetchDetailProduct = async () => {
        const { product } = this.props
        const { _id, title, description, sku_prefix, type, supplier, store_modes, product_label, currency } = product

        this.setState({
            id: _id,
            title,
            description,
            sku_prefix,
            type,
            supplier,
            store_modes,
            product_label,
            currency
        })
    }

    render() {
        const { product, index, onChangeStoreMode, onChangeSupplier } = this.props
        const {
            title,
            description,
            _id, category,
            created,
            store_modes,
            product_label,
            suppliers,
            type,
            production_time_min,
            production_time_max,
            production_method,
        } = product
        const categoryTitle = category instanceof Object ? category.title : ''
        const timeText = moment(created).format('HH:mm DD/MM/YYYY')
        const productLabel = productLabelOptions.find(label => label.value === product_label)

        return (
            <tr className="ProductsTableRow">
                <td>{index + 1}</td>
                <ProductTitle title={title} id={_id} />
                <ProductType type={type} />
                <ProductStoreMode storeModes={store_modes} onChangeStoreMode={onChangeStoreMode} />
                <td>{productLabel !== undefined ? productLabel.name : 'Product'}</td>
                <td>{production_method || ''}</td>
                <ProductDescription description={description} />
                <td>
                    <ProductsSupplier suppliers={suppliers} onChangeSupplier={onChangeSupplier} />
                </td>
                <td>{categoryTitle}</td>
                {/* <td className="ProductionTime">
                    <label className='d-block'>
                        {
                            production_time_min===production_time_max? production_time_min:
                            production_time_min+" - " + production_time_max
                        } 
                        {!!production_time_min && !!production_time_max&&
                            (production_time_min===1 && production_time_max===1? " day": " days")
                        }
                    </label>
                </td> */}
                <td>{timeText}</td>
                <td className="text-center">
                    <Link to={`/a/products/${_id}`}>
                        <i className="ti-pencil-alt" />
                    </Link>
                    {/* <span className="mx-2">|</span>
                    <Link to={{
                        pathname: '/a/products/new',
                        state: this.state
                    }}>
                        <i className="ti-layers" />
                    </Link> */}
                </td>
            </tr>
        )
    }
}

ProductsTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
}

export default ProductsTableRow
