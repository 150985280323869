import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Button, Modal, message, Form, Input, notification, Tooltip, Drawer, Collapse } from 'antd'
import { UndoOutlined, CloudDownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getListPrintFiles, getCountPrintFiles, checkFulfillmentNeedToPush, getOrderNotePackage } from "../../../services/api/OrdersServices"
import { searchProducts } from "../../../services/api/ProductsServices"
import { exportPrintFileItems } from "../../../services/api/ExportOrderServices"
import UseDebounce from './helpers/UseDebounce'
import PrintFilesTable from './PrintFilesTable'
import PrintFilesFilters from './PrintFilesFilters'
import { getListBrand } from '../../../services/api/3dApi'
import { getLocalData } from '../../../services/StorageServices'
import { getListRequests } from '../../../services/api/PlatformsServices'
import moment from 'moment'
import PrintFilesWarning from './PrintFilesWarning'

const WARNING_REVIEWS = [
    {
        key: 'order_tiktok',
        query: {
            statuses: ['review'],
            order_tiktok: 'yes',
            show_archive: 'hide_archive',
        },
        message: 'There are {quantity} TikTok items that need to be reviewed.',
        message2: 'There is {quantity} TikTok item that need to be reviewed.',
    },
    {
        key: 'updated_design',
        query: {
            statuses: ['review'],
            request_update_statuses: ['updated-design'],
            show_archive: 'hide_archive',
        },
        message: 'There are {quantity} items updated design that need to be reviewed.',
        message2: 'There is {quantity} item updated design that need to be reviewed.',
    },
]

const ListPrintFilePage = (props) => {
    const {isOpenPrintFilesWarning, onGetPackPackageNeedPush, onGetTikTokNeedReview, onGetDesignNeedReview, onClosePrintFilesWarning} = props
    const [listPrintFiles, setListPrintFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const [filters, setFilters] = useState({ statuses: ['review'] })
    const [selectField, setSelectField] = useState('package_names')
    const [listProducts, setListProducts] = useState([])
    const [visible, setVisible] = useState(false)
    const [link, setLink] = useState('')
    const [sheetName, setSheetName] = useState('')
    const [errorExport, setErrorExport] = useState('')
    const [loadingExport, setLoadingExport] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const [selectedOrders, setSelectedOrders] = useState([])
    const [listBrand, setListBrand] = useState([])
    const [packageNeedPush, setPackageNeedPush] = useState({})
    const [isShowNote, setIsShowNote] = useState(false)
    const [listNotePackages, setListNotePackages] = useState([])
    const [loadingCount, setLoadingCount] = useState(false)
    const [itemsRequest, setItemsRequest] = useState([])
    const [isClearing, setIsClearing] = useState(false)
    const [warningReviews, setWarningReviews] = useState({})

    const { order_number = '', package_names = '', auto_approved_status, request_update_statuses, created, product_types, statuses, supplier, update_design_count, show_archive, brand_name = '', has_note, paid_at, barcode_numbers = '', statuses_cut, push_status, namespaces = '', order_tiktok } = { ...filters }
    const orderNumberDebounce = UseDebounce(order_number, 500, isClearing)
    const orderPackageNameDebounce = UseDebounce(package_names, 500, isClearing)
    const brandNameDebounce = UseDebounce(brand_name, 500, isClearing)
    const namespaceDebounce = UseDebounce(namespaces, 500, isClearing)
    const barecodeNumberDebounce = UseDebounce(barcode_numbers, 500, isClearing)

    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

    useEffect(() => {
        if (!isClearing) {
            _fetchListPrintFiles()
        }
    }, [page, limit, orderNumberDebounce, orderPackageNameDebounce, barecodeNumberDebounce,
        auto_approved_status, request_update_statuses, created, isShowNote, isClearing,
        product_types, statuses, supplier, update_design_count, show_archive, brandNameDebounce, has_note, paid_at, barcode_numbers, statuses_cut, push_status, namespaceDebounce, order_tiktok])

    useEffect(() => {
        if (!isClearing) {
            _fetchCountPrintFiles()
        }
    }, [orderNumberDebounce, orderPackageNameDebounce, barecodeNumberDebounce,
        auto_approved_status, request_update_statuses, created, isClearing,
        product_types, statuses, supplier, update_design_count, show_archive, brandNameDebounce, has_note, paid_at, barcode_numbers, statuses_cut, push_status, namespaceDebounce, order_tiktok])

    const _handleSetSize = (size) => {
        setLimit(size)
        setPage(1)
    }

    const _handleSetPage = (page) => {
        setPage(page)
    }

    const _handleChangeShowNote = () => {
        setIsShowNote(!isShowNote)
        if (isShowNote) setListNotePackages([])
    }

    const _handleChageSelectField = (value) => {
        delete filters[selectField]
        setSelectField(value)
        setPage(1)
    }

    const _fetchOrderNotePackage = async (packageNames = []) => {
        try {
            const payload = {
                package_names: packageNames
            }

            const { data, success, message } = await getOrderNotePackage(payload)
            if (!success) return message.error(message)
            setListNotePackages(data)

        } catch (e) {
            setError(e.message)
        }
    }

    const _fetchFulfillmentNeddToPush = async (packageNames = []) => {
        try {
            const payload = {
                package_names: packageNames
            }

            const { data, success, message } = await checkFulfillmentNeedToPush(payload)
            if (!success) return message.error(message)
            setPackageNeedPush(data)
            onGetPackPackageNeedPush(data)
        } catch (e) {
            setError(e.message)
        }
    }

    const _fetchListPrintFiles = async () => {
        setLoading(true)
        setError('')
        setListPrintFiles([])
        try {
            fetchWarningReviews()
            const { statuses = [], statuses_cut = [], ...restFilters } = Object.assign({}, filters)
            const payload = {
                page,
                limit,
                ...restFilters,
                update_design_count: restFilters.update_design_count ? +restFilters.update_design_count : '',
                package_names: orderPackageNameDebounce.length ? orderPackageNameDebounce.trim().split(' ').map(i => i && i.trim()).filter(Boolean) : [],
                order_number: orderNumberDebounce.trim(),
                show_archive: show_archive ? show_archive : 'hide_archive',
                barcode_numbers: barecodeNumberDebounce.trim(),
                namespaces: namespaceDebounce.trim(),
                brand_name: brandNameDebounce.trim(),
            }
            if (!!statuses.length || !!statuses_cut.length) payload.statuses = [...statuses, ...statuses_cut]

            const { data, success, message } = await getListPrintFiles(payload)
            if (!success) {
                setLoading(false)
                return setError(message)
            }

            const { items = [] } = data

            if (!items.length) return setListPrintFiles([])

            setListPrintFiles(items)
            const listPackages = !!items.length ? items.map(i => i.name) : []
            if (listPackages.length === 0) return

            _fetchFulfillmentNeddToPush(listPackages)
            isShowNote && _fetchOrderNotePackage(listPackages)

            const allOrderItem = items && items.length > 0 ? items.map(i => i.order_item) : []
            if (allOrderItem.length > 0) { _fetchItemsRequest(allOrderItem) }

        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
            _resetSelectOrder()
        }
    }

    const _fetchCountPrintFiles = async () => {
        setLoadingCount(true)
        setError('')
        try {
            const { statuses = [], statuses_cut = [], ...restFilters } = Object.assign({}, filters)
            const payload = {
                ...restFilters,
                update_design_count: restFilters.update_design_count ? +restFilters.update_design_count : '',
                package_names: orderPackageNameDebounce.length ? orderPackageNameDebounce.trim().split(' ').map(i => i && i.trim()).filter(Boolean) : [],
                order_number: orderNumberDebounce.trim(),
                show_archive: show_archive ? show_archive : 'hide_archive',
                barcode_numbers: barecodeNumberDebounce.trim(),
                namespaces: namespaceDebounce.trim(),
                brand_name: brandNameDebounce.trim(),
            }
            if (!!statuses.length || !!statuses_cut.length) payload.statuses = [...statuses, ...statuses_cut]

            const { data, success, message } = await getCountPrintFiles(payload)

            if (!success) {
                setLoadingCount(false)
                return setError(message)
            }

            const { total = 0 } = { ...data }
            setTotal(total)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoadingCount(false)
        }
    }

    function filterLatestByOrderItem(data) {
        return Object.values(
            data.reduce((acc, item) => {
                const { order_item, created_at } = item
                if (!acc[order_item] || moment(item.created_at).isAfter(moment(acc[order_item].created_at))) {
                    acc[order_item] = item
                }
                return acc
            }, {})
        )
    }


    const _fetchItemsRequest = async (items) => {
        try {
            const payload = {
                orderItems: items
            }
            const { success, data, message } = await getListRequests(payload)
            if (!success) throw new Error(message)
            const { requests } = { ...data }
            setItemsRequest(requests && requests.length > 0 ? filterLatestByOrderItem(requests) : [])
        } catch (e) {
            alert(e.message)
        }
    }

    const _fetchListSuppliers = async () => {
        try {
            const { data, success, message } = await getListSuppliersV2({ active: true })

            if (!success) {
                console.log(message)
                return
            }

            const _listSuppliers = data.suppliers.map(option => ({ label: option.name, value: option._id }))
            setSuppliers(_listSuppliers)
        } catch (e) {
            message.error(e.message)
        }
    }

    const _fetchListProducts = async () => {
        try {
            const { data, success } = await searchProducts({ limit: 10000, unique_product_type: true })
            if (!success) return

            const newListProducts = data.products.map(option => ({ label: option.type, value: option._id })).filter(i => !!i && i.label)
            const uniqueProducts = newListProducts.filter((item, index, self) => index === self.findIndex((t) => t.label === item.label))
            setListProducts(uniqueProducts)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const onChangeStatus = (status, id) => {
        const newListPrintFiles = listPrintFiles.map(item => {
            if (item._id === id) {
                return {
                    ...item,
                    status
                }
            }
            return item
        })
        setListPrintFiles(newListPrintFiles)
    }

    const _handleResetFilter = () => {
        setIsClearing(true)
        setFilters({
            package_names: [],
        })
        setPage(1)
        setIsShowNote(false)
        setTimeout(() => { setIsClearing(false) }, 0)
    }

    const handleFilterPackagesNeedToPush = (packages = []) => {
        setIsClearing(true)
        const pakagesToString = packages.join(' ')
        setSelectField('package_names')
        setIsShowNote(true)
        onClosePrintFilesWarning()
        setFilters({
            package_names: pakagesToString,
            show_archive: filters.show_archive ? filters.show_archive : 'hide_archive',
        })
        setTimeout(() => { setIsClearing(false) }, 0)
    }

    const handleFilterWarningReviews = (config) => {
        onClosePrintFilesWarning()
        setFilters(_.get(config, 'query'))
    }

    const _handleChangeFilters = (field, value) => {
        if (field && (field.created || field.paid_at)) {
            setFilters({ ...filters, [Object.keys(field)[0]]: field.created ? field.created : field.paid_at })
            setPage(1)
            return
        }
        setFilters({ ...filters, [field]: value })
        setPage(1)
    }

    const _showModal = () => {
        setVisible(true)
    }

    const _hideModal = () => {
        setVisible(false)
    }

    const _handleOkCSV = async () => {
        setLoadingExport(true)
        setErrorExport('')

        try {
            const { statuses = [], statuses_cut = [], ...restFilters } = Object.assign({}, filters)

            const payload = {
                ...restFilters,
                page,
                limit,
                link,
                sheetName,
                package_names: orderPackageNameDebounce.length ? orderPackageNameDebounce.trim().split(' ').map(i => i && i.trim()).filter(Boolean) : [],
                show_archive: show_archive ? show_archive : 'hide_archive'
            }
            if (!!statuses.length || !!statuses_cut.length) payload.statuses = [...statuses, ...statuses_cut]

            const response = await exportPrintFileItems(payload)

            const { success, message: error } = response

            if (!success) {
                setLoadingExport(false)
                setErrorExport(error)

                return
            }

            setLoadingExport(false)
            _hideModal()

            notification.success({
                message: 'Export success',
                description: `Please check sheet ${sheetName} in link ${link}.`,
            })
        } catch (e) {
            setLoadingExport(false)
            setErrorExport(e.message)
        }
    }

    const _handleChangeLink = (e) => {
        setLink(e.target.value)
    }

    const _handleChangeName = e => {
        setSheetName(e.target.value)
    }

    const _updateOrdersSelected = (id, fulfillmentId, isAdded) => {
        const originOrders = listPrintFiles

        if (isAdded) {
            if (!selectedOrders.includes(id)) {
                setAllSelected(selectedOrders.length + 1 === originOrders.length)
                setSelectedOrders([...selectedOrders, {
                    orderItem: id,
                    fulfillmentItem: fulfillmentId
                }])
            }
        } else {
            const filtered = selectedOrders.filter(orderId => orderId.orderItem !== id)
            setAllSelected(false)
            setSelectedOrders(filtered)
        }
    }

    const _toggleSelectAll = (isChecked) => {
        if (isChecked) {
            // eslint-disable-next-line array-callback-return
            const orderIds = listPrintFiles.filter(order => order._id)

            const neworderIds = orderIds.map(item => {
                return {
                    orderItem: item._id,
                    fulfillmentItem: (!!item.fulfillment && !!item.fulfillment._id && item.fulfillment._id) || (!!item.fulfillment && item.fulfillment)
                }
            })

            if (orderIds.length > 0) {
                setAllSelected(true)
                setSelectedOrders(neworderIds)
            } else {
                setAllSelected(false)
                setSelectedOrders([])
            }
        } else {
            setAllSelected(false)
            setSelectedOrders([])
        }
    }

    const _resetSelectOrder = () => {
        setAllSelected(false)
        setSelectedOrders([])
    }

    const _fetchListBrand = async () => {
        try {
            const payload = {
                limit: 10000,
                page: 1
            }
            const { data, success } = await getListBrand(payload)
            if (!success) return

            const newListBrand = data.Brands.map(i => ({ label: i.name, value: i.name })).filter(i => !!i && i.label)
            setListBrand(newListBrand)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const fetchWarningReviews = async () => {
        try {
            const stateWarnings = {}
            await Promise.all(WARNING_REVIEWS.map(config => getCountPrintFiles(_.get(config, 'query')).then(res => {
                stateWarnings[config.key] = _.get(res, 'data.total')
            }) ))
            
            if(_.size(stateWarnings)) {
                setWarningReviews(stateWarnings)

                onGetTikTokNeedReview(stateWarnings.hasOwnProperty('order_tiktok') ? stateWarnings.order_tiktok : 0)
                onGetDesignNeedReview(stateWarnings.hasOwnProperty('updated_design') ? stateWarnings.updated_design : 0)
            }
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    useEffect(() => {
        _fetchListSuppliers()
        _fetchListProducts()
        _fetchListBrand()
    }, [])

    return (
        <div className='ListPrintFilePage pt-3'>
            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-3 mb-3'>
                <h1 className="PageTitle">Print files</h1>
                <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                    <Button onClick={_handleResetFilter}><UndoOutlined /> Reset filters</Button>
                    <Button disabled={loading} type="primary" onClick={_showModal} className="ml-3" ><CloudDownloadOutlined /> Export item</Button>

                    <Modal
                        title="Export items"
                        visible={visible}
                        onCancel={_hideModal}
                        confirmLoading={loadingExport}
                        footer={[
                            <Button key="back" onClick={_hideModal}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" loading={loadingExport} onClick={_handleOkCSV}>
                                Export
                            </Button>
                        ]}
                    >
                        <Form>
                            {
                                !!errorExport && errorExport.length > 0 && <div className="text-danger mb-3">{errorExport}</div>
                            }
                            <label htmlFor="link">
                                Link
                            </label>
                            <Input
                                id="link"
                                onFocus
                                defaultValue={link}
                                className="form-control mb-2"
                                onChange={_handleChangeLink}
                            />

                            <label htmlFor="tracking_name">
                                Name
                            </label>
                            <Input
                                id="tracking_name"
                                defaultValue={sheetName}
                                className="form-control mb-2"
                                onChange={_handleChangeName}
                            />
                            <div>
                                Share your Google Sheet to this account:
                                <br />
                                <strong>exporter@platform126.iam.gserviceaccount.com</strong>
                            </div>
                        </Form>
                    </Modal>
                </div>
            </div>
            <div className='SectionInner'>
                <div className='filter align-items-center'>
                    <div className="d-flex align-items-center justify-content-between">
                        <PrintFilesFilters
                            loading={loading || loadingCount}
                            suppliers={suppliers}
                            listProducts={listProducts}
                            listBrand={listBrand}
                            filters={filters}
                            isShowNote={isShowNote}
                            handleChangeFilters={_handleChangeFilters}
                            selectField={selectField}
                            handleChageSelectField={_handleChageSelectField}
                            onChangeShowNote={_handleChangeShowNote}
                            placeholders={{
                                order_number: "Search for order number",
                                package_names: "Search for package name",
                                barcode_numbers: "Search for barcode",
                                // namespace: "Search for order namespace",
                            }}
                        />

                    </div>
                </div>
                <div className="d-flex justify-content-end mb-3">
                    Total: <span className="font-weight-bold ml-2">{loadingCount ? <LoadingOutlined /> : total}</span>
                </div>
                <div className="table" style={{overflow: 'hidden', overflowX: 'auto'}}>
                    {error && <div className="text-danger">{error}</div>}
                    {/* {!loading && !loadingCount && (
                        <>
                            {!!packageNeedPush.packageNames && !!packageNeedPush.packageNames.length && (
                                <span className="total-package-push" onClick={() => handleFilterPackagesNeedToPush(packageNeedPush.packageNames)}>
                                    {!!packageNeedPush.realTotal && !!packageNeedPush.total && packageNeedPush.realTotal > packageNeedPush.total ? (
                                        <Tooltip overlayStyle={{ minWidth: '300px', fontSize: '16px' }} title={`Display up to ${packageNeedPush.total} packages. There are still ${packageNeedPush.realTotal - packageNeedPush.total} other packages to push`}>
                                            There are {packageNeedPush.total} packages to push.
                                        </Tooltip>
                                    ) : (
                                        `There are ${packageNeedPush.total} packages to push.`
                                    )}
                                </span>
                            )}
                            {_.map(WARNING_REVIEWS, config => {
                                const warning = warningReviews[config.key]

                                return (
                                    warning > 0 && (
                                        <div>
                                            <span className="total-package-push" onClick={() => handleFilterWarningReviews(config)}>
                                                {_.get(config, 'message').replace('{quantity}', warning)}
                                            </span>
                                        </div>
                                    )
                                )
                            })}
                        </>
                    )} */}
                    <PrintFilesTable
                        page={page}
                        limit={limit}
                        total={total}
                        loading={loading}
                        suppliers={suppliers}
                        data={listPrintFiles}
                        allSelected={allSelected}
                        selectedOrders={selectedOrders}
                        onChangeSize={_handleSetSize}
                        onChangePage={_handleSetPage}
                        reload={_fetchListPrintFiles}
                        onChangeStatus={onChangeStatus}
                        onResetSelectOrder={_resetSelectOrder}
                        onToggleSelectAll={_toggleSelectAll}
                        onUpdateOrdersSelected={_updateOrdersSelected}
                        permissionsData={permissionsData}
                        packageNeedPush={packageNeedPush}
                        listNotePackages={listNotePackages}
                        itemsRequest={itemsRequest}
                    />
                </div>

                {/* <PrintFilesWarning
                    // totalIssueConfirm={totalIssueConfirm}
                    // totalIssueChecking={totalIssueChecking}
                    // totalIssueReplace={totalIssueReplace}
                    // listIssueConfirm={listIssueConfirm}
                    totalPackagesNeedPush={packageNeedPush.total}
                    total
                    isOpenPrintFilesWarning={isOpenPrintFilesWarning}
                    onClosePrintFilesWarning={onClosePrintFilesWarning}
                    // listIssueCheck={listIssueCheck}
                    // listIssueReplace={listIssueReplace}
                    // onChangeInput={onChangeInput}
                    // onChangeSelect={handleChangeSelect}
                    // onChageSelectField={_handleChageSelectField}
                /> */}

                <div className='PackageIssueConfirm'>
                    <Drawer
                        title={`Print Files Warning`}
                        placement="left"
                        onClose={onClosePrintFilesWarning}
                        visible={isOpenPrintFilesWarning}
                        className='p-0 OrdersWarnings'
                        bodyStyle={{ padding: 0 }}
                        width={'377px'}
                    >
                        {!loading && !loadingCount && (
                            <>
                                {!!packageNeedPush.packageNames && !!packageNeedPush.packageNames.length && (
                                    <span className="total-package-push print-files-warning-item" onClick={() => handleFilterPackagesNeedToPush(packageNeedPush.packageNames)}>
                                        {!!packageNeedPush.realTotal && !!packageNeedPush.total && packageNeedPush.realTotal > packageNeedPush.total ? (
                                            <Tooltip overlayStyle={{ minWidth: '300px', fontSize: '16px' }} title={`Display up to ${packageNeedPush.total} ${packageNeedPush.total > 1 ? 'packages' : 'package'}. ${packageNeedPush.realTotal - packageNeedPush.total > 1 ? 'There are' : 'There is'} still ${packageNeedPush.realTotal - packageNeedPush.total} other ${packageNeedPush.realTotal - packageNeedPush.total > 1 ? 'packages' : 'package'} to push`}>
                                                {packageNeedPush.total > 1 ? 'There are' : 'There is'} {packageNeedPush.total} {packageNeedPush.total > 1 ? 'packages': 'package'} to push.
                                            </Tooltip>
                                        ) : (
                                            `${packageNeedPush.total > 1 ? 'There are' : 'There is'} ${packageNeedPush.total} ${packageNeedPush.total > 1 ? 'packages' : 'package'} to push.`
                                        )}
                                    </span>
                                )}
                                {_.map(WARNING_REVIEWS, config => {
                                    const warning = warningReviews[config.key]

                                    return (
                                        warning > 0 && (
                                            <>
                                                <span className="total-package-push print-files-warning-item" onClick={() => handleFilterWarningReviews(config)}>
                                                    {
                                                        warning > 1 ? _.get(config, 'message').replace('{quantity}', warning) : _.get(config, 'message2').replace('{quantity}', warning)
                                                    }
                                                </span>
                                            </>
                                        )
                                    )
                                })}
                            </>
                        )}
                    </Drawer>
                </div>
            </div>
        </div>
    )
}

export default ListPrintFilePage