import React from 'react'
import FilterSelect from './filters/FilterSelect'
import FilterDate from '../../shared/FilterDate'
import { Input, Select, Row, Col, Checkbox } from 'antd'
import { STATUS, REQUEST_UPDATE, ORDERS_ARCHIVED, STATUS_CUT, PUSHED_STATUS } from './helpers/PrintFileFilters'
import { SearchOutlined } from '@ant-design/icons'

const PrintFilesFilters = props => {
    const { loading, suppliers, filters, handleChangeFilters, placeholders, selectField, handleChageSelectField, listProducts, onChangeShowNote, isShowNote} = props
    return (
        <Row className="w-100" gutter={[24, 24]}>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Choose supplier" options={suppliers} allowClear={true} showSearch={true} value={filters.supplier} handleChangeFilters={handleChangeFilters} field="supplier" loading={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Choose print file status" options={STATUS} allowClear={true} showSearch={true} value={filters.statuses} handleChangeFilters={handleChangeFilters} field="statuses" mode="multiple" loading={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Choose cut status" options={STATUS_CUT} allowClear={true} showSearch={true} value={filters.statuses_cut} handleChangeFilters={handleChangeFilters} field="statuses_cut" mode="multiple" loading={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Choose product type" options={listProducts} allowClear={true} showSearch={true} value={filters.product_types} handleChangeFilters={handleChangeFilters} field="product_types" mode="multiple" type="product" loading={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect
                    style={{ width: '100%' }}
                    placeholder="Choose auto approved status"
                    options={[
                        {
                            label: 'Yes',
                            value: 'auto-approved',
                        },
                        {
                            label: 'No',
                            value: 'no',
                        },
                    ]}
                    allowClear={true}
                    value={filters.auto_approved_status}
                    handleChangeFilters={handleChangeFilters}
                    field="auto_approved_status"
                    loading={loading}
                />
            </Col>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Choose request update status" options={REQUEST_UPDATE} allowClear={true} value={filters.request_update_statuses} handleChangeFilters={handleChangeFilters} field="request_update_statuses" mode="multiple" loading={loading} />
            </Col>
            <Col span={8}>
                <Input.Group compact className="d-flex">
                    <Select value={selectField} style={{ width: 'min-width' }} onChange={handleChageSelectField} loading={loading}>
                        <Select.Option value="package_names">Package name</Select.Option>
                        <Select.Option value="order_number">Order number</Select.Option>
                        <Select.Option value="barcode_numbers">Barcode</Select.Option>
                    </Select>
                    <Input style={{ flex: 1 }} placeholder={placeholders[selectField]} onChange={e => handleChangeFilters(selectField, e.target.value)} value={filters[selectField]} loading={loading} disabled={loading} />
                </Input.Group>
            </Col>
            <Col span={4}>
                <Input style={{ width: '100%' }} placeholder="Search namespace" onChange={e => handleChangeFilters('namespaces', e.target.value)} value={filters.namespaces} suffix={<SearchOutlined />} disabled={loading} />
            </Col>
            <Col span={4}>
                <Input style={{ width: '100%' }} placeholder="Search brand name" onChange={e => handleChangeFilters('brand_name', e.target.value)} value={filters.brand_name} suffix={<SearchOutlined />} disabled={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect
                    style={{ width: '100%' }}
                    placeholder="Note status"
                    options={[
                        {
                            label: 'Have note',
                            value: 'yes',
                        },
                        {
                            label: 'No note',
                            value: 'no',
                        },
                    ]}
                    allowClear={true}
                    value={filters.has_note}
                    handleChangeFilters={handleChangeFilters}
                    field="has_note"
                    loading={loading}
                />{' '}
            </Col>
            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Show order" options={ORDERS_ARCHIVED} value={filters.show_archive || 'hide_archive'} handleChangeFilters={handleChangeFilters} field="show_archive" loading={loading} />
            </Col>

            <Col span={4}>
                <FilterSelect style={{ width: '100%' }} placeholder="Push status" options={PUSHED_STATUS} value={filters.push_status || ''} handleChangeFilters={handleChangeFilters} field="push_status" loading={loading} />
            </Col>
            <Col span={6}>
                <FilterDate heading="Created at:" className="w-100" rangePickerStyle={{ flex: 1 }} containerClass="d-flex" field="created" value={filters.created || {}} onDatesChange={handleChangeFilters} loading={loading} />
            </Col>
            <Col span={6}>
                <FilterDate heading="Paid at:" className="w-100" rangePickerStyle={{ flex: 1 }} containerClass="d-flex" field="paid_at" value={filters.paid_at || {}} onDatesChange={handleChangeFilters} loading={loading} />
            </Col>
            <Col span={4}>
                <FilterSelect
                    style={{ width: '100%' }}
                    placeholder="Order tiktok"
                    options={[
                        {
                            label: 'Yes',
                            value: 'yes',
                        },
                        {
                            label: 'No',
                            value: 'no',
                        },
                    ]}
                    allowClear={true}
                    value={filters.order_tiktok}
                    handleChangeFilters={handleChangeFilters}
                    field="order_tiktok"
                    loading={loading}
                />
            </Col>
            <Col span={4}>
                <Checkbox onChange={onChangeShowNote} checked={isShowNote}>Display note items</Checkbox>
            </Col>
        </Row>
    )
}

export default PrintFilesFilters
