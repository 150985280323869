import React, { Component } from 'react'
import ProductEditor from './ProductEditor'
import { createProduct, dulicateProduct, } from "../../../services/api/ProductsServices"
import getHistory from "../../../store/getHistory"
import { message } from "antd"

class CreateProductPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            validated: false,
            supplier: '',
            loading: false,
            isClone: false,
            error: '',
            product: {
                title: '',
                description: '',
                sku_prefix: '',
                type: '',
                supplier: '',
                attributes: [],
                variants: [],
                category: '',
                store_modes: ['normal'],
                productLabel: 'product',
                currency: '',
                two_sided_cost: '0.00',
                production_time_min: 0,
                production_time_max: 0,
                seller_production_time_max: 0,
                fast_production_time: 0,
            }
        }
    }

    componentDidMount() {
        const { dataClone } = this.props

        if (!!dataClone) {
            const {
                id,
                title,
                description,
                type,
                suppliers,
                store_modes,
                product_label,
                currency,
                two_sided_cost,
                production_time_min,
                production_time_max,
                seller_production_time_max,
            } = dataClone

            this.setState({
                isClone: true,
                product: {
                    ...this.state.product,
                    id: !!id ? id : '',
                    title: !!title ? title : '',
                    description: !!description ? description : '',
                    type: !!type ? type : '',
                    suppliers: !!suppliers ? suppliers : '',
                    store_modes: !!store_modes ? store_modes : ['normal'],
                    productLabel: !!product_label ? product_label : 'product',
                    currency: !!currency ? currency : '',
                    two_sided_cost: !!two_sided_cost ? two_sided_cost : (currency === 'USD' ? '0.00' : 0),
                    production_time_min: !!production_time_min,
                    production_time_max: !!production_time_max,
                    seller_production_time_max
                }
            })
        }
    }

    _handleValidate = () => {
        const { product } = this.state
        const { title, sku_prefix, currency, production_time_min, production_time_max, seller_production_time_max, fast_production_time } = product
        let flag = true

        this.setState({
            validated: true
        })

        if (title.trim() === '' || sku_prefix.trim() === '' || currency === '' || production_time_min === 0 || production_time_max === 0 || !production_time_min || !production_time_max || seller_production_time_max === 0 || !seller_production_time_max) {
            flag = false
        }

        return flag
    }

    _handSubmit = async () => {
        const validate = this._handleValidate()

        if (!validate) return

        this.setState({
            loading: true,
            error: ''
        })

        const { product, isClone } = this.state
        const {
            id: oldId,
            title,
            description,
            sku_prefix,
            type,
            supplier,
            store_modes,
            productLabel,
            currency,
            two_sided_cost,
            production_time_min,
            production_time_max,
            seller_production_time_max,
            fast_production_time,
            production_method,
        } = product
        let typeNew = type

        let productUpdate = Object.assign({}, {
            title: title,
            description: description,
            product_label: productLabel,
            type: typeNew,
            two_sided_cost: two_sided_cost,
            sku_prefix,
            supplier,
            store_modes,
            currency,
            production_time_min,
            production_time_max,
            seller_production_time_max,
            fast_production_time: fast_production_time ? fast_production_time : null,
        })

        if (production_method) {
            productUpdate.production_method = production_method
        }

        if (currency === 'VND') {
            productUpdate = {
                ...productUpdate,
                two_sided_cost: typeof productUpdate.two_sided_cost === 'string' ? productUpdate.two_sided_cost.replaceAll(/[,.]/g, '') : productUpdate.two_sided_cost
            }
        } else {
            productUpdate = {
                ...productUpdate,
                two_sided_cost: typeof productUpdate.two_sided_cost === 'string' ? productUpdate.two_sided_cost.replaceAll(/[,]/g, '') : productUpdate.two_sided_cost
            }
        }

        const { ...payload } = productUpdate

        try {
            const { success: sttRequest, message: errRequest, data } = !isClone ?
                await createProduct(payload) : await dulicateProduct(oldId, payload)

            if (!sttRequest) {
                message.error(errRequest)
                return this.setState({
                    loading: false,
                    error: errRequest
                })
            }

            const newProduct = Object.assign({}, data)

            this.setState({
                loading: false,
                error: ''
            }, () => {
                message.success(isClone ? 'Duplicate product success!' : 'Create product success!');
            })

            const history = getHistory()
            const location = {
                pathname: `/a/products/${newProduct._id}`,
                state: newProduct
            }

            history.push(location)
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message
            })
        }
    }

    _handleChangeData = (field, value) => {
        this.setState(({ product }) => {
            return {
                product: {
                    ...product,
                    [field]: value,
                }
            }
        }, () => {
            const { product } = this.state
            if (field !== 'productLabel') {
                this._handleValidate()
            }

            if (field === 'currency') {
                this.setState({
                    product: {
                        ...product,
                        two_sided_cost: product.currency === 'USD' ? '0.00' : 0
                    }
                })
            }
        })
    }

    render() {
        const { product, isClone, loading, validated, error } = this.state
        const {
            title,
            description,
            sku_prefix,
            type,
            supplier,
            store_modes,
            productLabel,
            currency,
            two_sided_cost,
            production_time_min,
            production_time_max,
            seller_production_time_max,
            fast_production_time,
            production_method,
        } = product

        return (
            <div className="CreateProductPage">
                <div className="row">
                    <div className={isClone ? 'col-md-9' : 'col-md-12'}>
                        <ProductEditor
                            isClone={isClone}
                            title={title}
                            error={error}
                            productLabel={productLabel}
                            description={description}
                            sku_prefix={sku_prefix}
                            type={type}
                            supplier={supplier}
                            loading={loading}
                            validated={validated}
                            storeMode={store_modes}
                            currency={currency}
                            two_sided_cost={two_sided_cost}
                            handSubmit={this._handSubmit}
                            handleChangeData={this._handleChangeData}
                            production_time_min={production_time_min}
                            production_time_max={production_time_max}
                            seller_production_time_max={seller_production_time_max}
                            fast_production_time={fast_production_time}
                            production_method={production_method}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateProductPage
