import React from 'react'

const TypeReport = (props) => {
    const { returnPageHeihgt, FilterHeight, totalItemQuantity, sortItemQuantity, sortBy, handleSortItemQuantity, handleSortCaseQuantity,
        totalCaseQuantity, sortCaseQuantity, showPercentIssue, baseLink, linkTo, itemQuantity, caseQuantity, issueTypes, partnerConfirm
    } = props

    const issueTypeBySort = React.useMemo(() => {
        if (sortBy === 'item') {
            if (sortItemQuantity) {
                return issueTypes.sort((a, b) => a.itemQuantity - b.itemQuantity)
            } else {
                return issueTypes.sort((a, b) => b.itemQuantity - a.itemQuantity)
            }
        } else {
            if (sortCaseQuantity) {
                return issueTypes.sort((a, b) => a.caseQuantity - b.caseQuantity)
            } else {
                return issueTypes.sort((a, b) => b.caseQuantity - a.caseQuantity)
            }
        }
    }, [sortItemQuantity, sortCaseQuantity, issueTypes, sortBy])

    return (
        <>
            <table className="table PackageIssueReportTable" style={{ top: `${returnPageHeihgt + FilterHeight}px` }}>
                <thead >
                    <tr>
                        {partnerConfirm === "1" && <th className="col-confirm" rowSpan={2}>Partner's confirm</th>}
                        <th className="col-type" rowSpan={2}>Issue Type</th>
                        <th className="col-item-quantity">Issue Item Quantity</th>
                        <th className="col-case-quantity">Case Quantity</th>
                    </tr>
                    <tr>
                        <th className="col-item-quantity text-right">
                            {totalItemQuantity} {
                                sortItemQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} />
                            }
                        </th>
                        <th className="col-case-quantity text-right">
                            {totalCaseQuantity} {
                                sortCaseQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'case' && 'text-primary'}`} onClick={handleSortCaseQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'case' && 'text-primary'}`} onClick={handleSortCaseQuantity} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !!issueTypeBySort.length ? issueTypeBySort.map((item, index) => (
                            <tr key={item._id}>
                                {partnerConfirm === "1" && index === 0 && <td className="col-confirm" rowSpan={issueTypeBySort.length}>Approve AND 50/50</td>}
                                <td className="col-type">{item.name || 0}</td>
                                <td className="col-item-quantity text-right">{showPercentIssue(item.itemQuantity, totalItemQuantity)}</td>
                                <td className="col-case-quantity text-right">
                                    {linkTo(baseLink, showPercentIssue(item.caseQuantity, totalCaseQuantity), {
                                        issue_type: item._id,
                                        supplier_confirm: partnerConfirm === "1" ? ['approve', 'half'] : [],
                                        status: ['confirmed']
                                    })}
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={6}>
                                    <p className="text-center text-muted mb-0">
                                        No data
                                    </p>
                                </td>
                            </tr>
                        )
                    }
                    {
                        partnerConfirm === "1" &&
                        <tr>
                            <td className="col-confirm">Reject</td>
                            <td className="col-type">-</td>
                            <td className="col-item-quantity text-right">{showPercentIssue(itemQuantity, totalItemQuantity)}</td>
                            <td className="col-case-quantity text-right">
                                {linkTo(baseLink, showPercentIssue(caseQuantity, totalCaseQuantity), {
                                    supplier_confirm: partnerConfirm === "1" ? ['reject'] : [],
                                    status: ['confirmed']
                                })}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <table className="table PackageIssueReportTable" id="Issue_Type_Report" style={{ top: `${returnPageHeihgt + FilterHeight}px`, display: 'none' }}>
                <thead style={{ display: 'none' }}>
                    <tr>
                        {partnerConfirm === "1" && <th className="col-confirm" rowSpan={2}>Partner's confirm</th>}
                        <th className="col-type" rowSpan={2}>Issue Type</th>
                        <th className="col-item-quantity">Issue Item Quantity</th>
                        <th className="col-case-quantity">Case Quantity</th>
                    </tr>
                    <tr>
                        <th className="col-item-quantity text-right">
                            {totalItemQuantity} {
                                sortItemQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'item' && 'text-primary'}`} onClick={handleSortItemQuantity} />
                            }
                        </th>
                        <th className="col-case-quantity text-right">
                            {totalCaseQuantity} {
                                sortCaseQuantity ? <i className={`fa fa-long-arrow-up cursor-pointer p-1 ${sortBy === 'case' && 'text-primary'}`} onClick={handleSortCaseQuantity} /> :
                                    <i className={`fa fa-long-arrow-down cursor-pointer p-1 ${sortBy === 'case' && 'text-primary'}`} onClick={handleSortCaseQuantity} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody style={{ display: 'none' }}>
                    {
                        !!issueTypeBySort && issueTypeBySort.map((item, index) => (
                            <tr key={item._id}>
                                {partnerConfirm === "1" && index === 0 && <td className="col-confirm" rowSpan={issueTypeBySort.length}>Approve AND 50/50</td>}
                                <td className="col-type">{item.name || 0}</td>
                                <td className="col-item-quantity text-right">{showPercentIssue(item.itemQuantity, totalItemQuantity)}</td>
                                <td className="col-case-quantity text-right">
                                    {showPercentIssue(item.caseQuantity, totalCaseQuantity)}
                                </td>
                            </tr>
                        ))
                    }
                    {
                        partnerConfirm === "1" &&
                        <tr>
                            <td className="col-confirm">Reject</td>
                            <td className="col-type">-</td>
                            <td className="col-item-quantity text-right">{showPercentIssue(itemQuantity, totalItemQuantity)}</td>
                            <td className="col-case-quantity text-right">
                                {showPercentIssue(caseQuantity, totalCaseQuantity)}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default TypeReport