import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { searchProducts } from '../../../services/api/ProductsServices'
import ProductsTable from './ProductsTable'
import ProductsPagination from './ProductsPagination'
import TableFilter from '../../shared/TableFilter'
import { getLocalData } from '../../../services/StorageServices'
import { Spin } from 'antd'

const ProductsPage = props => {

    const [term, setTerm] = useState('')
    const [type, setType] = useState()
    const [category, setCategory] = useState('');
    const [product_label, setProduct_label] = useState('')
    const [sku_prefix, setSku_prefix] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [products, setProducts] = useState([])
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [storeModes, setStoreModes] = useState([])
    const [loading, setLoading] = useState(false)
    const [currency, setCurrency] = useState('')
    const [supplier, setSupplier] = useState([])
    const [available_tiktok, setAvailable_tiktok] = useState('')
    const [productionMethod, setProductionMethod] = useState('')

    useEffect(() => {
        _fetchProducts()
    }, [page, limit, term, type, sku_prefix, category, storeModes, product_label, currency, supplier, available_tiktok, productionMethod])

    const _fetchProducts = async () => {
        setLoading(true)
        try {
            const payload = {
                page: page,
                limit: limit,
                term: term,
                category: category,
                product_label,
                type,
                currency,
                sku_prefix,
                available_tiktok
            }

            if (storeModes.length > 0) {
                Object.assign(payload, { storeModes })
            }

            if (supplier.length > 0) {
                Object.assign(payload, { supplier })
            }

            if (productionMethod) payload.production_method = productionMethod

            const { data, success, message } = await searchProducts(payload)

            if (!success) {
                setError(message)
                setLoading(false)
            }

            const { products, pages, total } = data

            setProducts(products)
            setPages(pages)
            setTotal(total)
            setError('')
            setLoading(false)
        } catch (e) {
            setError(e.message)
            setLoading(false)
        }
    }

    const _handPagePagination = (selectedPage) => {
        setPage(selectedPage)
    }

    const _onSubmitFilter = ({ limit, value, field }) => {
        const fieldMap = {
            'Title': 'term',
            'Type': 'type',
            'SKU Prefix': 'sku_prefix'
        }
        const limitNumber = Number(limit)
        const parsedField = fieldMap[field]
        const parsedValue = value.trim()

        setLimit(limitNumber)
        setPage(1)
        setTerm(parsedField === 'term' ? parsedValue : '')
        setType(parsedField === 'type' ? parsedValue : '')
        setSku_prefix(parsedField === 'sku_prefix' ? parsedValue : '')
    }

    const _handleSelectCategory = categoryId => {

        setCategory(categoryId)
        setPage(1)
    }

    const _handleChangeStoreMode = (storeModes) => {

        setStoreModes([...storeModes])
        setPage(1)
    }

    const _handleChangeSupplier = (supplier) => {

        setSupplier(supplier)
        setPage(1)
    }

    const _handleChangeProductLable = (product_label) => {

        setProduct_label(product_label)
        setPage(1)
    }

    const _handleChangeCurrency = (value) => {

        setCurrency(value)
        setPage(1)
    }

    const _handleChangeAvailableTiktok = (value) => {
        setAvailable_tiktok(value)
        setPage(1)
    }

    const _handleChangeProductionMethod = (value) => {
        setProductionMethod(value)
        setPage(1)
    }

    if (error) return <i className='text-danger fs-14'>{error}</i>
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

    return (
        <div className="ProductsPage pt-3">
            <div className='Create-product'>
                <div className='row justify-content-between'>
                    <div className='col-auto'>
                        <h1 className="PageTitle">Products</h1>
                    </div>

                    <div className='col-auto ml-3 ml-sm-0 mr-3 mr-lg-0'>
                        {(permissionsData.includes('ffm_product_line_create') || permissionsData.includes('ffm_view_cost')) &&
                            <Link to="/a/products/new" className="btn btn-primary">Create Product</Link>
                        }
                    </div>
                </div>
            </div>

            <div className='SectionInner'>
                <div className='filter align-items-center'>
                    <TableFilter
                        showStoreMode={true}
                        showProductLabel={true}
                        showCurrency={true}
                        showProductionMethod={true}
                        isDateOn={false}
                        selectorStatus={true}
                        currency={currency}
                        storeModes={storeModes}
                        showSupplier={true}
                        supplier={supplier}
                        defaultLimit={limit}
                        productionMethod={productionMethod}
                        total={total}
                        searchField={['Title', 'Type', 'SKU Prefix']}
                        placeholders={{
                            'Title': 'Search for product title',
                            'Type': 'Search for product type',
                            'SKU Prefix': 'Search for sku prefix',
                        }}
                        onSubmitForm={_onSubmitFilter}
                        handleSelectCategory={_handleSelectCategory}
                        onChangeStoreMode={_handleChangeStoreMode}
                        onChangeLabel={_handleChangeProductLable}
                        onChangeCurrency={_handleChangeCurrency}
                        onChangeSupplier={_handleChangeSupplier}
                        showAvailableTiktok={true}
                        onChangeAvailableTiktok={_handleChangeAvailableTiktok}
                        onChangeProductionMethod={_handleChangeProductionMethod}
                    />
                </div>

                {
                    loading && <div>Loading...</div>
                }

                <div className="mt-3">
                    <Spin spinning={loading} tip='Loading...'>
                        <ProductsTable
                            loading={loading}
                            products={products}
                            onChangeStoreMode={_handleChangeStoreMode}
                            onChangeSupplier={_handleChangeSupplier}
                        />
                        <ProductsPagination pages={pages} page={page}
                            onPagePagination={_handPagePagination} />
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default ProductsPage
