export const checkDisallowRequestUpdate = (store, type) => {
    switch (store) {
        case 'gkxp089':
            return true
        case '1736mjc':
            return [
                // 'CUSTOM_ORN_0103MTM',
                // '2L_KEY_0102MT',
                // '1W_ORN_0103MT',
                'L1W_ORN_0103MT',
                // 'CUSTOM_ORN_0103IN3'
            ].includes(type)
        case '4fi1m09':
        case 'lgm4om8':
        case 'jplv2tp':
        case 'zaawbk1':
        case '5xq00z2':
        case '913y4f1':
        case '0nzud4i':
            return [
                '1S_CER_ORN_0303TM',
                '1S_ALU_ORN_0500D7TM',
                'L1S_CER_ORN_0303TM',
                '2S_CER_ORN_0303TM',
                'L2S_CER_ORN_0303TM',
                'MEN_BOXER_3D',
                'PRE_SWEATER_3D'
            ].includes(type)
        default:
            return false
    }
}
