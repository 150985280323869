import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Select, Tooltip } from "antd"
import { requestUpdateDesign } from "../../../../services/api/PlatformsServices"
import { ReactComponent as AttachIcon } from "../../icons/attach.svg"
import { ReactComponent as DeleteIcon } from "../../icons/trash.svg"
import { ReactComponent as RequestIcon } from "../../../../statics/assets/images/request-icon.svg"
import { getLanguageStore } from "../../../../services/api/PlatformIntergationServices"
import AutoCompletedText from "../shared/AutoCompletedText"

const RequestUpdateModal = (props) => {
    const { visible, toggleModal, title, value, orderTemplate, namespace, type, orderItemId, reload, isDisableRequestUpdate, brandName } = props
    const [files, setFiles] = useState('')
    const [content, setContent] = useState('')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [language, setLanguage] = useState('vi')

    const _onCloseModal = () => {
        toggleModal()
        setContent('')
        setFiles({})
    }

    const _handleChangeFile = (e) => {
        const { files } = e.target

        if (files[0].size > 5242880) {
            setError('File size is lager than 5M')
        } else {
            setError('')
            setFiles(files)
        }
    }

    const _onDeleteFile = (index) => {
        const newFilter = Object.assign({}, files)
        delete newFilter[index]

        setFiles(newFilter)
    }

    const _handleChangeContent = (value) => {
        setContent(value)
    }

    const _onChangeFile = (e) => {
        e.target.value = ''
    }

    const _onSubmit = async () => {
        await _onCreateUpdateDesignRequest()
    }

    const _onCreateUpdateDesignRequest = async () => {
        setLoading(true)
        setError('')

        try {
            const formData = new FormData()

            if (content === '') {
                setLoading(false)
                return setError('Description is required.')
            }

            if (files.length > 0) {
                formData.append('image', files[0])
            }

            formData.append('content', content)

            const { success, message: mess } = await requestUpdateDesign(formData, orderItemId)

            if (!success) {
                setLoading(false)

                return setError(mess)
            }

            message.success('Created request success.')
            reload()
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const _fetchLanguageStore = async () => {
        setError('')

        try {
            const response = await getLanguageStore(namespace)

            const { success, data, message: error } = response

            if (!success) {
                return setError(error)
            }

            setLanguage((data.hasOwnProperty('preferred_language') ? data.preferred_language : 'vi'))
        } catch (e) {
            setError(e.message)
        }
    }

    const handleShowMessage = (store, type, brandName) => {
        const storeConfig = ["m825fdn", "e6tfim4", "yt6tepe", "2u8dh9j"]
        const typeConfig = ["BASEBALL_CAP_13", "KNIT_SWEATER_38", "YO_KNIT_SWTER_38"]
        const BRANDNAME = "UID"

        let warning = ''

        if (storeConfig.includes(store) && typeConfig.includes(type)) warning = "Báo designer check lại."
        if (brandName === BRANDNAME && typeConfig.includes(type)) warning = "Báo designer check lại."
        if (storeConfig.includes(store) && typeConfig.includes(type) && brandName === BRANDNAME) warning = "Báo designer check lại."

        return warning
    }

    useEffect(() => {
        visible && _fetchLanguageStore()
    }, [visible])

    const keysFile = Object.keys(files)

    return (
        <div className='RequestUpdateModal Icon'>
            <Modal
                className="RequestModal"
                visible={visible}
                title={`${title} - ${orderTemplate}`}
                onCancel={_onCloseModal}
                footer={[
                    <Button key="back" onClick={_onCloseModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={_onSubmit} disabled={isDisableRequestUpdate}>
                        Send Request
                    </Button>
                ]}
            >
                <div className="ContentModal">
                    <div className="RequestType">
                        <label htmlFor="Type">Request type:</label>
                        <Select name="request-type" id="Type" value={value} disabled>
                            <Select.Option value="design">Design</Select.Option>
                            <Select.Option value="address">Address</Select.Option>
                        </Select>
                    </div>
                    <AutoCompletedText
                        namespace={namespace}
                        language={language}
                        label='Description:'
                        parentClass='RequestDescription'
                        onChange={_handleChangeContent}
                        content={content}
                    />
                    <div className="AttachFile">
                        <input
                            id={`${value}_select_file`}
                            type="file"
                            accept='image/png, image/jpeg, image/jpg'
                            multiple={false}
                            onChange={_handleChangeFile}
                            onClick={_onChangeFile}
                        />
                        <label htmlFor={`${value}_select_file`} id={`${value}_select_file`}
                            className='d-block mb-0 cursor-pointer'>
                            Attach file
                        </label>
                        {/* {
                            isDisableRequestUpdate &&
                            <div className="text-danger font-weight-bold">
                                Tạm thời không support request update design với {namespace} - {type}
                            </div>
                        } */}
                        {
                            namespace === "0nzud4i" && type === "1S_CER_ORN_0303TM" &&
                            <i className="text-danger font-weight-bold h4">Seller yêu cầu không cần sửa design.</i>
                        }
                        {
                            namespace === "5xq00z2" &&
                            <i className="text-danger font-weight-bold h4">Đồng thời báo lại AM </i>
                        }
                        {
                            !!handleShowMessage(namespace, type, brandName) &&
                            <i className="text-danger font-weight-bold h4">{handleShowMessage(namespace, type, brandName)}</i>
                        }
                    </div>
                    <div className="ListFileInput">
                        <ul>
                            {
                                keysFile && keysFile.length > 0 && keysFile.map((item, index) => (
                                    <li key={index}>
                                        <div className="Left">
                                            <AttachIcon />
                                            <span className="FileName">{files[item].name}</span>
                                        </div>
                                        <DeleteIcon className="Delete" onClick={() => _onDeleteFile(item)} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        !!error && error.length > 0 && <div className="text-danger mt-2">
                            {error}
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default RequestUpdateModal