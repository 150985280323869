import React from 'react'

const ProductsTableHead = () => {
    return (
        <thead className="ProductsTableHead">
            <tr>
                <th>#</th>
                <th>Title</th>
                <th>Type</th>
                <th>Store mode</th>
                <th>Product label</th>
                <th>Production Methods</th>
                <th>Description</th>
                <th>Supplier</th>
                <th>Category</th>
                {/* <th>Production time</th> */}
                <th>Updated</th>
                <th>Actions</th>
            </tr>
        </thead>
    )
}

export default ProductsTableHead
