import React from 'react'
import ProductsTableHead from "./ProductsTable/ProductsTableHead"
import ProductsTableRow from "./ProductsTable/ProductsTableRow"
import PropTypes from 'prop-types'
import { Empty } from 'antd'

const ProductsTable = props => {
    const { products, onChangeStoreMode, onChangeSupplier } = props

    return (
        <div className="ProductsTable">
            <table className='table'>
                <ProductsTableHead />
                <tbody>
                    {products && products.length > 0 ?
                        products.map((product, i) => {
                            return <ProductsTableRow key={product._id} index={i} product={product}
                                onChangeStoreMode={onChangeStoreMode} onChangeSupplier={onChangeSupplier}
                            />
                        }) :
                        <tr>
                            <td colSpan={11}>
                                <Empty className='w-100 my-3' description="No products found" />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

ProductsTable.propTypes = {
    products: PropTypes.array.isRequired,
}

export default ProductsTable
