import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { message, Spin } from "antd"
import ProductProvider from "./ProductProvider"
import { getProductAttributes, getProductDetail, updateProductAttributeV2 } from "../../../services/api/ProductsServices"
import ReturnPage from "../../shared/ReturnPage"
import ProductDetailInfo from "./product-detail/ProductDetailInfo"
import ProductAttributes from "./product-attributes/ProductAttributes"
import ProductCategories from "./product-category/ProductCategories"
import ProductImages from "./product-image/ProductImages"
import ProductDimension from "./product-dimension/ProductDimension"
import ProductVariants from "./product-variants/ProductVariants"
import broker from "./ProductBroker"
import { getLocalData } from '../../../services/StorageServices'
import ProductSurcharge from './product-surcharge/ProductSurcharge'
import ChildProducts from './child-products/ChildProducts'

ProductPage.propTypes = {
    id: PropTypes.string
}

function ProductPage(props) {
    const { id } = props

    const [loadingDetail, setLoadingDetail] = useState(false)
    const [loadingAttributes, setLoadingAttributes] = useState(false)
    const [loadingUpdateAttributes, setLoadingUpdateAttributes] = useState(false)

    const [errorDetail, setErrorDetail] = useState('')
    const [errorAttributes, setErrorAttributes] = useState('')
    const [errorUpdateAttributes, setErrorUpdateAttributes] = useState('')

    const [productDetail, setProductDetail] = useState({})
    const [listAtt, setListAtt] = useState([])
    const [originalListAtt, setOriginalListAtt] = useState([])

    const _handleChangeState = (field, value) => {
        setProductDetail({
            ...productDetail,
            [field]: value
        })
    }

    const _handleCreateAttribute = () => {
        setListAtt([
            ...listAtt,
            {
                name: '',
                value_type: 'select',
                values: []
            }
        ])
    }

    const _handleRemoveAttribute = (index) => {
        const listAttUpdated = listAtt.filter((att, idx) => idx !== index)

        setListAtt(listAttUpdated)
    }

    const _handleChangeAttState = (index, field, value) => {
        let listAttUpdated = []

        listAtt.map((att, idx) => {
            if (idx === index) {
                return listAttUpdated.push({ ...att, [field]: value })
            }

            return listAttUpdated.push(att)
        })

        setListAtt(listAttUpdated)
    }

    const _handleDiscardChangeState = () => {
        setListAtt(originalListAtt)
    }

    const _fetchProductDetail = async () => {
        setLoadingDetail(true)
        setErrorDetail('')

        try {
            const response = await getProductDetail(id)

            const { data, success, message } = response

            if (!success) {
                setLoadingDetail(false)

                return setErrorDetail(message)
            }

            setLoadingDetail(false)

            const {
                title, description, sku_prefix, type, suppliers, child_products,
                category, store_modes, product_label, currency,
                two_sided_cost, dimension_conditions, fast_production_time,
                production_time_min, production_time_max, seller_production_time_max, embroidery_surcharge,
                production_method,
            } = data
            const categoryTitle = !!category ? category.title : ''

            setProductDetail({
                child_products,
                title,
                description,
                sku_prefix,
                suppliers,
                type,
                two_sided_cost,
                category: categoryTitle,
                store_modes: store_modes || ['normal'],
                product_label: product_label || 'product',
                currency: currency || 'USD',
                dimension_conditions: dimension_conditions || [],
                embroidery_surcharge,
                production_time_min,
                production_time_max,
                seller_production_time_max,
                fast_production_time,
                production_method,
            })
        } catch (e) {
            setLoadingDetail(false)
            setErrorDetail(e.message)
        }
    }

    const _fetchListAttributes = async () => {
        setLoadingAttributes(true)

        try {
            const response = await getProductAttributes(id)

            const { success, message, data } = response

            if (!success) {
                setLoadingAttributes(false)
                return setErrorAttributes(message)
            }

            setLoadingAttributes(false)
            setListAtt(data)
            setOriginalListAtt(data)
        } catch (e) {
            setLoadingAttributes(false)
            setErrorAttributes(e.message)
        }
    }

    const _onUpdateListAttributes = async (payload) => {
        setLoadingUpdateAttributes(true)
        setErrorUpdateAttributes('')

        try {

            const response = await updateProductAttributeV2(id, payload)

            const { success, message: error } = response

            if (!success) {
                setLoadingUpdateAttributes(false)

                return setErrorUpdateAttributes(error)
            }

            setLoadingUpdateAttributes(false)

            message.success('Add attributes success!')

            await _fetchProductDetail()
            await _fetchListAttributes()

            broker.publish('GET_LIST_VARIANTS')
        } catch (e) {
            setLoadingUpdateAttributes(false)
            setErrorUpdateAttributes(e.message)
        }
    }

    useEffect(() => {
        _fetchProductDetail()
        _fetchListAttributes()
    }, [])

    const handleReset = () => {
        _fetchProductDetail()
        _fetchListAttributes()
    }

    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const disableAll = !permissionsData.includes('ffm_product_line_update')

    return (
        <ProductProvider
            onUpdateListAttributes={_onUpdateListAttributes}
        >
            <div className='ProductPage'>
                <ReturnPage url="/a/products" title="Products" />

                <div className="row">
                    <div className="col-md-9 col-sm-12">
                        <Spin spinning={loadingDetail} tip="Getting product information...">
                            <ProductDetailInfo
                                errorDetail={errorDetail}
                                productId={id}
                                productDetail={productDetail}
                                onChangeState={_handleChangeState}
                                disableAll={disableAll}
                                handleReset={handleReset}
                            />
                        </Spin>

                        <Spin spinning={loadingAttributes} tip="Getting product attributes...">
                            <ProductAttributes
                                errorAttributes={errorAttributes}
                                loading={loadingUpdateAttributes}
                                error={errorUpdateAttributes}
                                productDetail={productDetail}
                                listAtt={listAtt}
                                onAddAtt={_handleCreateAttribute}
                                onDeleteAtt={_handleRemoveAttribute}
                                onDiscardChangeAtt={_handleDiscardChangeState}
                                onChangeAtt={_handleChangeAttState}
                                disableAll={disableAll}
                            />
                        </Spin>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <ProductCategories
                            productId={id}
                            category={productDetail.category}
                            onUpdateState={_handleChangeState}
                            disableAll={disableAll}
                        />
                        <ProductImages productId={id} disableAll={disableAll} />
                        {
                            productDetail.hasOwnProperty('dimension_conditions') || productDetail.hasOwnProperty('embroidery_surcharge') ?
                                <ProductDimension
                                    productId={id}
                                    dimensions={productDetail.dimension_conditions || []}
                                    surcharges={productDetail.embroidery_surcharge || []}
                                    onUpdateState={_handleChangeState}
                                    disableAll={disableAll}
                                /> : ''
                        }

                        {/* <ProductSurcharge
                            productId={id}
                            surcharges={productDetail.embroidery_surcharge || []}
                            dimensions={productDetail.dimension_conditions || []}
                            onUpdateState={_handleChangeState}
                            disableAll={disableAll}
                        /> */}
                        <ChildProducts productId={id} productDetail={productDetail} reset={_fetchProductDetail} disableAll={disableAll} />
                    </div>

                    <div className="col-md-12">
                        <ProductVariants
                            productId={id}
                            suppliers={productDetail.suppliers}
                            productDetail={productDetail}
                            listAtt={listAtt}
                            fetchProductDetail={_fetchProductDetail}
                            disableAll={disableAll}
                        />
                    </div>
                </div>
            </div>
        </ProductProvider>
    )
}

export default ProductPage
