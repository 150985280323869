import React, { Component } from 'react'
import { CSVLink } from "react-csv"

class FileTemplate extends Component {
    render() {
        const { importType = '' } = this.props
        const FileTemplateData = [
            ["Package name", "Store", "Supplier", "Tracking", "Shipping carrier", "Original carrier", "Issue type", "Solution", "FFM's note", "FFM's proof", "Partner's confirm", "Partner's note"],
            [],
        ]

        const FileTemplateMCUSData = [
            ["Package name", "Store", "Supplier", "Tracking", "Shipping carrier", "Original carrier", "Issue type", "Solution", "FFM's note", "FFM's proof"],
            [],
        ]

        const templateImport = importType === "fac_us" ? FileTemplateMCUSData : FileTemplateData

        return (
            <div className='FileTemplate mb-3'>
                <div className="d-flex align-items-center flex-wrap">
                    <h3 className='Title mb-0'>Upload file CSV</h3>
                    <CSVLink
                        data={templateImport}
                        filename={"issue-shipment-template.csv"}
                        className="btn btn-link"
                    >
                        Download template
                    </CSVLink>
                    {/* <CSVLink
                        data={FileTemplateMCUSData}
                        filename={"issue-shipment-template-MCUS-DFWUS.csv"}
                        className="btn btn-link"
                    >
                        Download template MCUS, DFWUS
                    </CSVLink> */}
                </div>
                <p className='mb-0'>{`( File name have not contain special characters and limit 5MB )`}</p>
            </div>
        )
    }
}

export default FileTemplate
