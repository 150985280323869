import React, { useState, useEffect } from 'react'
import { Input, Modal, message, Button, Switch, Select, Tooltip, Dropdown, Menu, Space } from 'antd'
import MarkAsDone from '../actions/MarkAsDone'
import GenerateTemplate from '../actions/GenerateTemplate'
import ChangeOnHoldState from '../actions/ChangeOnHoldState'
import RequestUpdateModal from '../actions/RequestUpdateModal'
import { updateLinkDesign, updatePrintFilesStatus } from '../../../../services/api/OrdersServices'
import { getSupplierType } from '../../../../services/api/ProductsServices'
import { checkDisallowRequestUpdate } from '../helpers/checkDisallowRequestUpdate'
import ModalUpdateCustomizedArtworks from './ModalUpdateCustomizedArtworks'

const OrderActions = (props) => {
    const { orderId, reload, itemNumber, onFetchOrders, namespace, type = '', size = '', fulfillmentId, designFront, color = '', partnerSku = '',
        name, orderItemId, status, onChangeStatus, tagFalseDesign, designHood, designSleeves, designBack, suppFulfill, brandName } = props
    const [visible, setVisible] = useState(false)
    const [visibleRequestUpdate, setVisibleRequestUpdate] = useState(false)
    const [urlDesign, setUrlDesign] = useState({
        front: designFront ? designFront : '',
        back: designBack ? designBack : '',
        hood: designHood ? designHood : '',
        sleeves: designSleeves ? designSleeves : '',
    })
    const [checked, setChecked] = useState(false)
    const [variant, setVariant] = useState({
        type: '',
        size: '',
        color: '',
        partner_sku: ''
    })

    const isEditDesign = Object.keys(urlDesign).some(item => !!urlDesign[item].trim())
    const isDisableRequestUpdate = checkDisallowRequestUpdate(namespace, type)

    const handleCancel = () => {
        setVisible(false)
        setChecked(false)
        setUrlDesign({
            front: designFront ? designFront : '',
            back: designBack ? designBack : '',
            hood: designHood ? designHood : '',
            sleeves: designSleeves ? designSleeves : '',
        })
        setVariant({
            type: '',
            size: '',
            color: '',
            partner_sku: ''
        })
    }

    const handleOpen = async () => {
        setVisible(true)
        setVariant({
            ...variant,
            type,
            size,
            color,
            partner_sku: partnerSku
        })
    }

    const toggleRequestUpdateModal = () => {
        setVisibleRequestUpdate(!visibleRequestUpdate)
    }

    const handleChangeSide = (side, value) => {
        setUrlDesign({
            ...urlDesign,
            [side]: value
        })
    }

    const handleChangeVariant = (item) => {
        setVariant({
            ...variant,
            ...item
        })
    }

    const handleSaveEdit = async () => {
        try {
            const payload = { ...urlDesign }
            const { type, size, color, partner_sku } = Object.assign({}, variant)
            if (checked) {
                payload.type = type || ''
                payload.size = size || ''
                payload.color = color || ''
                payload.partner_sku = partner_sku || ''
            }

            const { success, message: mess } = await updateLinkDesign(payload, orderId)
            if (!success) return message.error(mess)

            message.success('Update Customize Artwork Success!')
            handleCancel()
            Modal.confirm({
                title: 'Do you want to retry generating this item?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => handleRetryPrintFile(),
                onCancel: () => reload(),
            })
        } catch (error) {
            message.error(error.message)
        }
    }

    const handleRetryPrintFile = async () => {
        try {
            const { success, data, message: mess } = await updatePrintFilesStatus(fulfillmentId, orderId, 'retry')
            if (!success) {
                throw new Error(mess)
            }
            message.success('Print file retry success!!!')
        } catch (error) {
            Modal.error({
                title: 'Print file retry error',
                content: error.message
            })
        } finally {
            reload()
        }
    }
    const orderTemplate = `${name}_${itemNumber}`

    const changeStatus = (value, id) => {
        onChangeStatus(value, id)
        message.success('Trạng thái đã thay đổi')
    }

    const handleCheck = () => {
        setChecked(!checked)
    }

    const _showSubMenu = () => {
        // const {productionStatus, haveStatus, cancelStatus, tags} = this.props
        // const user = getLocalData('userRole')

        return (
            <Menu style={{ width: '180px' }}>
                {
                    <Menu.Item onClick={handleOpen}>Edit design</Menu.Item>
                }

                {
                    // !isDisableRequestUpdate &&
                    <Menu.Item onClick={toggleRequestUpdateModal}>Request update</Menu.Item>
                }
            </Menu>
        )
    }

    return (
        <div className='OrderActions'>
            <div className={`Actions mb-3`}>
                <MarkAsDone orderId={orderId} fulfillmentId={fulfillmentId} onSuccess={changeStatus} />
                <GenerateTemplate orderId={orderId} fulfillmentId={fulfillmentId} onChangeStatus={changeStatus} />
                <ChangeOnHoldState orderId={orderId} fulfillmentId={fulfillmentId} status={status} onChangeStatus={changeStatus} />
                {/* {index === 0 && <Tooltip title='Edit design' placement='bottom'>
                    <span className={`Icon cursor-pointer Edit p-2 ${tagFalseDesign ? 'FalseDesignAction' : ''}`} onClick={handleOpen}>
                        <i className='fa fa-pencil-square-o' />
                    </span>
                </Tooltip>} */}
                <div className='MoreActions Icon'>
                    <Dropdown overlay={_showSubMenu} trigger={['click']}>
                        <span className='Icon'><i className='fa fa-ellipsis-h' /></span>
                    </Dropdown>
                </div>
                {
                    // !isPushedAt &&
                    <RequestUpdateModal
                        visible={visibleRequestUpdate}
                        toggleModal={toggleRequestUpdateModal}
                        namespace={namespace}
                        title="Request update order"
                        value='design'
                        onFetchOrders={onFetchOrders}
                        orderTemplate={orderTemplate}
                        orderItemId={orderItemId}
                        reload={reload}
                        type={type}
                        brandName={brandName}
                        isDisableRequestUpdate={isDisableRequestUpdate}
                    />
                }

                {visible && <ModalUpdateCustomizedArtworks
                    visible={visible}
                    urlDesign={urlDesign}
                    onSaveEdit={handleSaveEdit}
                    onCancel={handleCancel}
                    checked={checked}
                    onCheck={handleCheck}
                    onChangeVariant={handleChangeVariant}
                    variant={variant}
                    onChangeSide={handleChangeSide}
                    isEditDesign={isEditDesign}
                    supplierId={suppFulfill}
                />}
            </div>
        </div>
    )
}

export default OrderActions