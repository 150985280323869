import React from 'react'
import { Drawer, Collapse, List, Tooltip } from 'antd'
import { Link } from "react-router-dom"

const { Panel } = Collapse

const PrintFilesWarning = ({ isOpenIssueConfirm, listIssueCheck, totalIssueConfirm, totalIssueChecking,
    listIssueConfirm, onCloseIssueConfirm, onChangeInput, listIssueReplace, totalIssueReplace, onChangeSelect, onChageSelectField }) => {
    const headerConfirmed = <span>Package issue confirmed (<span className='text-primary' onClick={() => handleFindNotification('confirm')}>{totalIssueConfirm}</span>)</span>
    const headerChecking = <span>Package issue checking (<span className='text-primary' onClick={() => handleFindNotification('checking')}>{totalIssueChecking}</span>)</span>
    const headerReplacing = <span>Package need replacing (<span className='text-primary' onClick={() => handleFindNotification('replace')}>{totalIssueReplace}</span>)</span>

    const handleFindPackageName = (value, status, field) => {
        if (status === "replace") {
            onChageSelectField(field)
            onChangeInput(field, value)
            onChangeSelect('solution', status)
            onChangeSelect('Status', [])
            onChangeSelect('notificationType', '')
        }

        if (status === "confirmed") {
            onChageSelectField(field)
            onChangeInput(field, value)
            onChangeSelect('Status', [status])
            onChangeSelect('solution', null)
            onChangeSelect('notificationType', '')
        }

        if (status === "checking") {
            onChageSelectField(field)
            onChangeInput(field, value)
            onChangeSelect('Status', [status])
            onChangeSelect('solution', null)
            onChangeSelect('notificationType', '')
        }

        onCloseIssueConfirm()
    }

    const handleFindNotification = (status) => {
        onChangeSelect('notificationType', status)
        onChangeInput('reference_number', '')
        onChangeInput('package_name', '')
        onChangeSelect('Status', [])
        onChangeSelect('solution', '')
        onCloseIssueConfirm()
    }

    const splitReferenceNumber = (text) => {
        if (!text) return ''
        const regex = /F\d+/
        return regex.test(text)
    }

    return (
        <div className='PackageIssueConfirm'>
            <Drawer
                title={`Package issue notification`}
                placement="left"
                onClose={onCloseIssueConfirm}
                visible={isOpenIssueConfirm}
                className='p-0 OrdersWarnings'
                bodyStyle={{ padding: 0 }}
                width={'377px'}
            >
                <Collapse accordion defaultActiveKey={["1", "2"]}>
                    {/* {listIssueConfirm.length > 0 && (
                        <Panel header={headerConfirmed} key="1" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={listIssueConfirm}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        <Link
                                            to="#"
                                            className='mr-1'
                                            onClick={() => handleFindPackageName(item.reference_number, 'confirmed')}
                                        >
                                            {splitReferenceNumber(item.reference_number) ?
                                                <Tooltip title={item.reference_number} placement='topRight'>
                                                    {`${item.reference_number.substring(0, 25)}...`}
                                                </Tooltip> :
                                                item.reference_number
                                            }
                                        </Link>
                                    </li>
                                )}
                            />
                        </Panel>
                    )} */}

                    {listIssueCheck.length > 0 && (
                        <Panel header={headerChecking} key="2" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={listIssueCheck}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        {item.reference_number ?
                                            <Link
                                                to="#"
                                                className='mr-1'
                                                onClick={() => handleFindPackageName(item.reference_number.join(', '), 'replace', 'reference_number')}
                                            >
                                                {splitReferenceNumber(item.reference_number.join(', ')) ?
                                                    <Tooltip title={item.reference_number.join(', ')} placement='topRight'>
                                                        {`${item.reference_number.join(', ').substring(0, 25)}...`}
                                                    </Tooltip> :
                                                    <Tooltip title={`${item.reference_number.join(', ')}`} placement='topRight'>
                                                        {`${item.reference_number.join(', ').substring(0, (item.reference_number[0].length + 1) || 25)}${item.reference_number.length > 1 ? '...' : ''}`}
                                                    </Tooltip>
                                                }
                                            </Link> :
                                            <Link
                                                to="#"
                                                className='mr-1'
                                                onClick={() => handleFindPackageName(item.package_name, 'replace', 'package_name')}
                                            >
                                                {splitReferenceNumber(item.package_name) ?
                                                    <Tooltip title={item.package_name} placement='topRight'>
                                                        {`${item.package_name.substring(0, 25)}...`}
                                                    </Tooltip> :
                                                    item.package_name
                                                }
                                            </Link>
                                        }
                                    </li>
                                )}
                            />
                        </Panel>
                    )}

                    {listIssueReplace.length > 0 && (
                        <Panel header={headerReplacing} key="3" showArrow={false}>
                            <List
                                pagination={{
                                    className: 'text-right mt-3 p-0',
                                    size: "small",
                                    pageSize: 5,
                                }}
                                dataSource={listIssueReplace}
                                renderItem={(item, index) => (
                                    <li className="px-2 py-2" key={index}>
                                        {item.reference_number && item.reference_number.length > 0 ?
                                            <Link
                                                to="#"
                                                className='mr-1'
                                                onClick={() => handleFindPackageName(item.reference_number.join(', '), 'replace', 'reference_number')}
                                            >
                                                {splitReferenceNumber(item.reference_number.join(', ')) ?
                                                    <Tooltip title={`${item.reference_number.join(', ')}`} placement='topRight'>
                                                        {`${item.reference_number.join(', ').substring(0, 25)}...`}
                                                    </Tooltip> :
                                                    <Tooltip title={`${item.reference_number.join(', ')}`} placement='topRight'>
                                                        {`${item.reference_number.join(', ').substring(0, (item.reference_number[0].length + 1) || 25)}${item.reference_number.length > 1 ? '...' : ''}`}
                                                    </Tooltip>
                                                }
                                            </Link> :
                                            <Link
                                                to="#"
                                                className='mr-1'
                                                onClick={() => handleFindPackageName(item.package_name, 'replace', 'package_name')}
                                            >
                                                {splitReferenceNumber(item.package_name) ?
                                                    <Tooltip title={item.package_name} placement='topRight'>
                                                        {`${item.package_name.substring(0, 25)}...`}
                                                    </Tooltip> :
                                                    item.package_name
                                                }
                                            </Link>
                                        }
                                    </li>
                                )}
                            />
                        </Panel>
                    )}
                </Collapse>
            </Drawer>
        </div>
    )
}

export default PrintFilesWarning